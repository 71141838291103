import React, { FC, useState, useEffect } from 'react'
import { Button, Radio, Space, Checkbox, Col, Row } from 'antd'
import { useHistory } from 'react-router'
import styles from './QuestionsDetail.module.scss'
import { DetailItem } from '@/components'
import { IDetailItem } from '@/components/DetailItem/DetailItem'
import { getQuestionsInfo } from '@/api/paper/index'
import { getParameters } from '@/utils/mainUtils'

interface QuestionsDetailProps {}

const QuestionsDetail: FC<QuestionsDetailProps> = (props) => {
  const detailArr: IDetailItem[] = [
    {
      title: '试题名称',
      dataIndex: 'name',
    },
    {
      title: '试题类型介绍',
      dataIndex: 'questionType',
      render: (record: any) => (
        <div>{record.questionType === 1 ? '单选' : '多选'}</div>
      ),
    },
    {
      title: '正确答案',
      dataIndex: 'answer',
    },
    {
      title: '排序',
      dataIndex: 'orderNum',
    },
    {
      title: '答案解析',
      dataIndex: 'answerExplain',
      isRow: true,
      render: (record: any) => <div>{record.answerExplain}</div>,
    },
    {
      title: '选项',
      dataIndex: 'answerExplain',
      isRow: true,
      render: (record: any) =>
        record.questionType === 1 ? (
          <Radio.Group>
            <Space direction="vertical">
              {record.options &&
                JSON.parse(record.options).map((option: any, index: number) => (
                  <Radio key={option.mark} value={option.mark}>
                    {option.mark}: {option.item}
                  </Radio>
                ))}
            </Space>
          </Radio.Group>
        ) : (
          <Checkbox.Group style={{ width: '100%' }}>
            <Space direction="vertical">
              {record.options &&
                JSON.parse(record.options).map((option: any, index: number) => (
                  <Checkbox key={option.mark} value={option.mark}>
                    {option.mark}: {option.item}
                  </Checkbox>
                ))}
            </Space>
          </Checkbox.Group>
        ),
    },
  ]
  const history = useHistory()
  const [detailData, setDetailData] = useState<any>()
  const { id } = getParameters()

  const getQuestionsDetail = async () => {
    const res = await getQuestionsInfo(id)
    setDetailData(res?.message ? res?.data : res)
  }

  useEffect(() => {
    getQuestionsDetail()
  }, [])

  return (
    <div className={`${styles.root} edit-page`}>
      <div className={styles.detailTitle}>基本信息</div>
      <DetailItem detailArr={detailArr} detailData={detailData} />

      <div style={{ textAlign: 'right', marginTop: 20 }}>
        <Button
          className={styles.closeBtn}
          style={{ marginRight: 8 }}
          onClick={() => history.goBack()}
        >
          返回
        </Button>
      </div>
    </div>
  )
}

export default QuestionsDetail
