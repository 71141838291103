// plugins插件
export const plugins: string =
  'codesample code lists table wordcount image template imagetools'
// 字体配置为px
export const fontSize: string =
  '12px 14px 16px 18px 20px 24px 28px 32px 36px 40px 48px 56px 72px'
// toolbar
export const toolbar: string[] = [
  'undo redo | styles fontsize bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | formatselect | fontselect | fontsizeselect | forecolor backcolor | bullist numlist outdent indent | lists link table | removeformat | codesample code | image',
]
interface MenuItemsTypes {
  title: string
  items: string
}
type MenuItemsKeysListTypes =
  | 'file'
  | 'edit'
  | 'view'
  | 'insert'
  | 'format'
  | 'tools'
  | 'table'
type MenuTypes = Partial<Record<MenuItemsKeysListTypes, MenuItemsTypes>>
// 菜单配置
export const menu: MenuTypes = {
  file: { title: '文件', items: 'newdocument ' },
  edit: {
    title: 'Edit',
    items: 'undo redo | cut copy paste | selectall | searchreplace',
  },
  view: { title: 'View', items: 'code' },
  insert: {
    title: 'Insert',
    items:
      'image link media codesample inserttable | charmap emoticons hr | pagebreak nonbreaking anchor toc | insertdatetime',
  },
  format: {
    title: 'Format',
    items:
      'bold italic underline strikethrough superscript subscript codeformat | formats blockformats fontformats fontsizes align | forecolor backcolor | removeformat',
  },
  tools: {
    title: 'Tools',
    items: 'spellchecker spellcheckerlanguage | code wordcount',
  },
  table: {
    title: 'Table',
    items: 'inserttable | cell row column | tableprops deletetable',
  },
}
// enubar
export const enubar: string = 'file edit view insert format tools table'


interface CodesampleLanguagesTypes {
  text: string
  value: string
}
export const codesampleLanguages: CodesampleLanguagesTypes[] = [
  { text: 'HTML/XML', value: 'markup' },
  { text: 'JavaScript', value: 'javascript' },
  { text: 'CSS', value: 'css' },
  { text: 'PHP', value: 'php' },
  { text: 'Ruby', value: 'ruby' },
  { text: 'Python', value: 'python' },
  { text: 'Java', value: 'java' },
  { text: 'C', value: 'c' },
  { text: 'C#', value: 'csharp' },
  { text: 'C++', value: 'cpp' },
  { text: 'Scala', value: 'scala' },
]
