import React, {useState, useEffect, FC} from 'react'
// import {HashRouter, Route, Switch} from 'react-router-dom'
import { useHistory, useLocation } from 'react-router';
import styles from './index.module.scss'
import {useMappedState, useDispatch} from "redux-react-hook";
import { Layout, Menu, Breadcrumb, Dropdown, Avatar, message, Modal} from 'antd';
import Logo from '@/assets/image/logo.png';
import { DELETE_USER_INFO } from '@/redux/action'
import {IState} from '@/redux/interface'
import {
  AppstoreOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  ReadOutlined,
  ProfileOutlined,
  PieChartOutlined,
  BankOutlined,
  PayCircleOutlined,
  FilePptOutlined
} from '@ant-design/icons';
import RouteComponent from './Route';
import { getParameters } from '@/utils/mainUtils';

const { Header, Content, Sider } = Layout;
const { SubMenu } = Menu;

interface ILocation {
  hash: string
  pathname: string
  search: string
  state?: any
}


interface IMenuItem {
  key: string;
  icon?: React.ReactNode;
  title: string;
  children?: IMenuItem[];
  isHide?: boolean; // 是否显示
}

const Index: FC = (props) => {
  const [menuKey, setMenuKey] = useState('system/user')
  const userInfoState = useMappedState((state: IState) => state.UserInfoReducer.userInfo);
  const [collapsed, setCollapsed] = useState(false);
  const [breadcrumbData, setBreadcrumbData] = useState<string[]>([]);
  const [defaultOpenKeys, setDefaultOpenKeys] = useState<string[]>(['system']); // 展开的父菜单
  const { pathname, search } = useLocation<ILocation>();
	const { id, breadcrumbName } = getParameters(); // 如果有id参数就是编辑页面，不是编辑页的换个字段
  const currentRoute = pathname?.split('/')?.filter(item => item);
  const history = useHistory();
  const dispatch = useDispatch();
  const menuArr: IMenuItem[] = [
    {
      key: 'curriculum',
      title: '课程管理',
      icon: <ReadOutlined />,
      children: [
        { key: 'curriculum/list', title: '课程列表' },
        { key: 'curriculum/list/edit', isHide: true, title: '新增' },
        { key: 'curriculum/list/detail', isHide: true, title: '课程详情' },
      ]
    },
    {
      key: 'sketch',
      title: '图文课程管理',
      icon: <ProfileOutlined />,
      children: [
        { key: 'sketch/list', title: '图文课程列表' },
        { key: 'sketch/list/add', isHide: true, title: '新增图文课' },
        { key: 'sketch/list/edit', isHide: true, title: '编辑图文课' },
        { key: 'sketch/list/detail', isHide: true, title: '图文课详情' },
      ]
    },
    {
      key: 'paper',
      title: '试卷管理',
      icon: <FilePptOutlined />,
      children: [
        { key: 'paper/list', title: '试卷管理' },
        { key: 'paper/list/add', isHide: true, title: '新增试卷' },
        { key: 'paper/list/edit', isHide: true, title: '编辑试卷' },
      ]
    },
    {
      key: 'paperStatistics',
      title: '试卷类统计',
      icon: <FilePptOutlined />,
      children: [
        { key: 'paper/statistics',  title: '试卷类统计' },
      ]
    },
    {
      key: 'questions',
      title: '试题管理',
      isHide: true,
      icon: <FilePptOutlined />,
      children: [
        { key: 'questions/list', isHide: true, title: '试题管理' },
        { key: 'questions/list/add', isHide: true, title: '新增试题' },
        { key: 'questions/list/edit', isHide: true, title: '编辑试题' },
        { key: 'questions/list/detail', isHide: true, title: '预览试题' },
      ]
    },
    {
      key: 'user',
      title: '用户管理',
      icon: <UserOutlined />,
      children: [
        { key: 'user/userList', title: '用户列表' },
        { key: 'user/userList/detail', isHide: true, title: '用户详情' },
      ]
    },
  ]

  const menuData = [
    { label: '我的', icon: '', url: '/myPage/enterpriseProfile' },
  ]

  const menu = <Menu>
    {
      // menuData.map(item => (
      //   <Menu.Item
      //     key={item.url}
      //     onClick={() => {
      //       history.push(item.url);
      //     }}
      //   >
      //     <div className="flex-all-center menu-content">
      //       {/* <img alt="" src={item.icon} className={styles.topMenuIcon}/> */}
      //       <UserOutlined style={{ marginRight: 5 }} />
      //       {item.label}
      //     </div>
      //   </Menu.Item>
      // ))
    }
    <Menu.Item
      onClick={() => {
        Modal.info({
          title: '请您联系管理员进行密码修改',
          onOk() {},
          className: 'forgetPassword'
        });
      }}
    >
      <div className="flex-all-center menu-content">
        {/* <img alt="" src={item.icon} className={styles.topMenuIcon}/> */}
        {/* <UserOutlined style={{ marginRight: 5 }} /> */}
        忘记密码
      </div>
    </Menu.Item>
    <Menu.Item
      onClick={() => {
        dispatch({ type: DELETE_USER_INFO })
        document.cookie = "";
        message.success('退出登录成功');
        history.push('/login')
      }}
    >
      <div className="sign-out-menu">
        退出登录
      </div>
    </Menu.Item>
  </Menu>

  useEffect(() => {
    setDefaultOpenKeys(currentRoute.length > 0 ? [currentRoute[0]] : [])
  }, [])

  useEffect(() => {
    const len = currentRoute.length;
    if (len > 1) { // 当路由只有两级的时候
      const key1 = currentRoute[0];
      const key2 = currentRoute[1];
      setMenuKey(`${key1}/${key2}`);
      // const keyChildren = key2.split("-");  // 判断是否有加-，有的话是下一级页面
      // if (keyChildren.length > 1) {
      //   const cKey1 = keyChildren[0];
      //   const cKey2 = keyChildren[1];
      //   setMenuKey(`${key1}/${cKey1}`);
      //   setBreadcrumbData([key1, `${key1}/${cKey1}`, `${key1}/${cKey1}-${cKey2}`]);
      // } else {
      //   setMenuKey(`${key1}/${key2}`);
      //   setBreadcrumbData([key1, `${key1}/${key2}`]);
      // }
      const arr = [key1, `${key1}/${key2}`];
      if (len > 2) { // 当路由只有三级的时候
        const key3 = currentRoute[2];
        arr.push(`${key1}/${key2}/${key3}`);
        if (len > 3) { // 当路由只有四级的时候
          const key4 = currentRoute[3];
          arr.push(`${key1}/${key2}/${key3}/${key4}`);
          if (len > 4) { // 当路由只有五级的时候
            const key5 = currentRoute[4];
            arr.push(`${key1}/${key2}/${key3}/${key4}/${key5}`);
          }
        }
      }
      setBreadcrumbData(arr);
    }
  }, [pathname]);

  const onLink = (route: string) => {
    history.push(route)
  }

  const menuClick = (params: any) => {
    const { key, keyPath } = params
    localStorage.setItem('formValue', '');
    setMenuKey(key)
    setBreadcrumbData(keyPath?.reverse());
    onLink(`/${key}`)
  }

  const returnTitle = (key: string) => {
    let newTitle: string | undefined = '';
    const filterArr = menuArr.filter(item => item.key === key);
    if (filterArr.length) {
      const { title } = filterArr[0];
      newTitle = title;
    } else {
      menuArr.forEach(item => {
        item.children?.forEach(each => {
          if (key === each.key) {
            newTitle = each.title;
          }
        })
      })
    }
    return newTitle;
  }

  const onOpenChange = (openKeys: any) => {
    setDefaultOpenKeys(openKeys);
  }

  // const clickBreadcrumb = (key: string) => {
  //   if (breadcrumbData.length === 2) {
  //     const route = key?.split("/")[1];
  //     onLink(route);
  //   } else {
  //     history.goBack();
  //   }
  // }

  return (
    <Layout className={styles.container} style={{ background: '#fff' }}>
      <Sider
        breakpoint="lg"
        // onBreakpoint={(broken) => { console.log(broken); }}
        // onCollapse={(collapsed, type) => { console.log(collapsed, type); }}
        width={200}
        collapsed={collapsed}
      >
        <div
          className={styles.sidedHead}
          style={{ 
            justifyContent: !collapsed ? 'space-between' : 'center',
            padding: !collapsed ? '10px 19px 15px' : '33px 19px'
          }}
        >
          <div className={styles.sidedHeadL}>
            { !collapsed && <img src={Logo} alt="" className={styles.logo} /> }
          </div>
          <div className={`${styles.collapsedBox} flex-all-center`} onClick={() => setCollapsed(!collapsed)}>
            {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined)}
          </div>
        </div>
        <Menu
          // theme="dark"
          onOpenChange={onOpenChange}
          mode="inline"
          selectedKeys={[menuKey]}
          onClick={menuClick}
          // defaultOpenKeys={defaultOpenKeys}
          openKeys={defaultOpenKeys}
          // overflowedIndicator={<CaretDownOutlined style={{ color: '#BBC0D0' }} />}
          // expandIcon={<CaretUpOutlined style={{ color: '#BBC0D0' }} />}
          className='homepage-menus'
        >
          {
            menuArr.map((item: IMenuItem) => !item.isHide && (
              <SubMenu key={item.key} icon={item.icon} title={item.title}>
                {
                  item.children?.map((each: IMenuItem) => !each.isHide && (
                    <Menu.Item key={each.key}>
                      { each.icon }
                      <span className="nav-text">{each.title}</span>
                    </Menu.Item>
                  ))
                }
              </SubMenu>
              //  <SubMenu key={item.key} icon={item.icon} title={item.title} children={item.children} />
            ))
          }
        </Menu>
      </Sider>
      <Layout>
        <Header className={styles.indexHeader}>
          {/* <div className={styles.userInfo}>
            <div className={`${styles.collapsedBox} flex-all-center`} onClick={() => setCollapsed(!collapsed)}>
              {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined)}
            </div>
          </div> */}
            {
              breadcrumbData.length > 0 && (
                <div className={styles.breadcrumbBox}>
                  <Breadcrumb separator=">">
                    {
                      breadcrumbData.map((item: string, index: number) => (
                        <Breadcrumb.Item key={item}>
                          <span
                            // onClick={() => {
                            //   if ([0, 2].includes(index) || breadcrumbData.length === 2) return;
                            //   // clickBreadcrumb(item);
                            //   // onLink(item);
                            //   // history.replace(item)
                            //   history.goBack();
                            // }}
                          >
                            {index === 2 && id ? '编辑' : returnTitle(item)}
                          </span>
                        </Breadcrumb.Item>
                      ))
                    }
                  </Breadcrumb>
                </div>
              )
            }
          <div className={styles.headerRight}>
            欢迎你，
            <Dropdown overlay={menu} placement="bottomRight" className={styles.my}>
              <div className={styles.myDropdown}>
                {/* <UserOutlined /> */}
                {/* <Avatar size={32} icon={<UserOutlined />} /> */}
                <span className={styles.nameBox}>
                  <span className={styles.name}>{userInfoState?.name}</span>(管理员)
                </span>
                {/* <CaretDownOutlined size={10} /> */}
              </div>
            </Dropdown>
          </div>
        </Header>
        <Content className='content-container'>
          <div className='content-container-wrap'>
            <div className={styles.pageContent}>
              <RouteComponent />
            </div>
          </div>
        </Content>
      </Layout>
    </Layout>
  )
}

export default Index
