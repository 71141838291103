import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
// import request from './request'
import { createStore } from 'redux'
import Reducers from "./redux/reducers";
// import actions from "./redux/action";
import moment from 'moment';
import zh_CN from 'antd/es/locale-provider/zh_CN';
// import en_US from 'antd/es/locale-provider/en_US';
import 'moment/locale/zh-cn';
import { ConfigProvider } from 'antd';
// import { AxiosInstance } from 'axios'
import { StoreContext } from "redux-react-hook";
import { message } from 'antd'
import App from './App';

// declare global {
//   interface Window {
//     $request: AxiosInstance;
//   }
// }

moment.locale('zh-cn')
// window.$request = request

const store = createStore(Reducers)

message.config({
  top: 88,
  duration: 3,
  maxCount: 3,
  rtl: false,
})

ReactDOM.render(
  <StoreContext.Provider value={store}>
    <ConfigProvider locale={zh_CN}>
      <App />
    </ConfigProvider>
  </StoreContext.Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
