import React, { FC } from 'react'
import { Popconfirm } from 'antd';
import styles from './Popconfirm.module.scss'
import yesIcon from '@/assets/components/yes.svg'
import noIcon from '@/assets/components/no.svg'
import { PopconfirmProps } from 'antd/lib/popconfirm'

const TablePopconfirm: FC<PopconfirmProps> = (props) => {

  const { title } = props
  const isString = typeof title === 'string'
  const titleNode = <span className={styles.popTitle}>{title}</span>
  return (
    <Popconfirm
      okText={<span className={styles.popYesBtn}><img alt="" src={yesIcon}/>是</span>}
      cancelText={<span className={styles.popNoBtn}><img alt="" src={noIcon}/>否</span>}
      icon={<span></span>}
      {...props}
      title={isString ? titleNode : title}
    >
      {props.children}
    </Popconfirm>
  )

}

export default TablePopconfirm
