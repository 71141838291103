import $request from '@/request'

type IGetSketchList = {
  name?: string
  pageNo?: number
  pageSize?: number
}

export type IGetSketchItem<T> = {
  [x: string]: T
}

export interface IGetSketchListRes<T> {
  list: IGetSketchItem<T>[]
  total: number
}


// export interface UploadProps<T> {
//   id?: T
// }
// export interface IUploadComponentProps extends UploadProps<number> {
//   name?: string
// }

// const s: IUploadComponentProps = {
//   id: 1,
//   name: '123',
// }
// console.log(s)

// interface sss extends Promise<IGetSketchListRes<any>>{

// }

export const getSketchList = (
  params: IGetSketchList
): Promise<IGetSketchListRes<any>> => {
  return $request.get('/manage/text_course/list', { params })
}

export interface addSketchTypes {
  name: string //课程标题
  content:string //内容
  status:number // 状态
  description?:string //描述
}

export interface updateSketchTypes extends addSketchTypes{
  id:string,
  [x:string]:any
}

export const deleteSketch = (id: number): Promise<any> => {
  return $request.delete(`/manage/text_course/${id}`)
}

// export const hideCurriculum = (id: number): Promise<any> => {
//   return $request.put(`/manage/course/hide?id=${id}`)
// }

export const getSketchInfo = (id: number): any => {
  return $request.get(`/manage/text_course/${id}`)
}
export const updateSketch = (id:string,data: updateSketchTypes):any => {
  return $request.put(`/manage/text_course/${id}`, data)
}


/**
 *  创建图文课程
 * @param data 
 * @returns 
 */
export const addSketch = (data: addSketchTypes):any => {
  return $request.post('/manage/text_course', data)
}
