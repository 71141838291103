/*
 * @Description:
 * @Autor: xingfutan
 * @Date: 2021-06-23 22:12:06
 * @LastEditors: xingfutan
 * @LastEditTime: 2021-08-12 09:59:09
 */
export const getParameters = (url?: string) => {
  const href = url ?? window.location.href
  const query = href.substring(href.indexOf('?') + 1)
  const vars = query.split('&')
  const obj: any = {}
  for (var i = 0; i < vars.length; i++) {
    let pair = vars[i].split('=')
    obj[pair[0]] = pair[1]
  }
  return obj
}

/**
 *  文件下载
 * @param url 下载地址
 * @param fileName 文件名称
 */
export function downloadFile(url: string, fileName: string) {
  let xhr: XMLHttpRequest = new XMLHttpRequest()
  // if (window.XMLHttpRequest) {
  //   xhr = new XMLHttpRequest()
  // } else {
  //   // eslint-disable-next-line no-undef
  //   // xhr = new ActiveXObject('Microsoft.XMLHTTP')
  // }
  xhr.open('GET', url, true)
  xhr.responseType = 'blob'
  xhr.onload = function () {
    if (this.status === 200) {
      // downloadFile(fileName, xhr.response)
      const fileBlob = new Blob([xhr.response])
      const oa = document.createElement('a')
      oa.href = URL.createObjectURL(fileBlob)
      oa.download = fileName
      document.body.appendChild(oa)
      oa.click()
    }
  }
  xhr.send()
}

// 文件查看，打开新窗口
export const showFile = (url?: string) => {
  if (!url) return
  const oa = document.createElement('a')
  oa.href = url
  oa.target = '_blank'
  // oa.download = fileName;
  document.body.appendChild(oa)
  oa.click()
}

// 查找两个数组中不重复的数据
export const getArrDifference = (arr1: any, arr2: any) => {
  return arr1.concat(arr2).filter((v: any, i: number, arr: any) => {
    return arr.indexOf(v) === arr.lastIndexOf(v)
  })
}
// 生成uuid
export const getUUID = (): string => {
  const timestamp = Date.now().toString(16)
  const random = Math.floor(Math.random() * 0x10000).toString(16)
  const mask = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r =
      c === 'x'
        ? random[Math.floor(Math.random() * 4)]
        : c === 'y'
        ? timestamp[Math.floor(Math.random() * 5)]
        : c
    return r.toUpperCase()
  })
  return mask
}
// 生成4位随机数
export const getUUID4 = (): string => {
  const characters =
    '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
  const charactersLength = characters.length
  let uuid = ''

  for (let i = 0; i < 4; i++) {
    uuid += characters[Math.floor(Math.random() * charactersLength)]
  }

  return uuid
}
