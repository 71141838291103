import React, { FC, useState, useEffect } from 'react'
import { Form, Button, Spin, message, InputNumber, Select, Input } from 'antd'
import FormWrap from '@/components/FormWrap/FormWrap'
import { useHistory } from 'react-router'
import _ from 'lodash'
import {
  addQuestions,
  updateQuestions,
  getQuestionsInfo,
} from '@/api/paper/index'
import styles from './OperateQuestions.module.scss'
import './formReset.scss'
import { getParameters } from '@/utils/mainUtils'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'

interface OperateQuestionsProps {
  [x: string]: any
}
const { Option } = Select
const statusOptions = [
  { label: '单选', value: 1 },
  { label: '多选', value: 2 },
]
const OperateQuestions: FC<OperateQuestionsProps> = (props) => {
  const history = useHistory()
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [loadingText, setLoadingText] = useState('')
  const { id, paperid } = getParameters()
  const handleChange = (e: any) => {
    if (e === 1) {
      form.setFieldsValue({ answer: '' })
    } else {
      form.setFieldsValue({ answer: [] })
    }
  }
  const contactInfoItems = [
    [
      {
        label: '试题类型',
        name: 'questionType',
        autoRequired: true,
        isRow: true,
        renderTag: () => (
          <Select placeholder="请选择状态" onChange={handleChange}>
            {statusOptions.map((i, index) => (
              <Option key={index} value={i.value}>
                {i.label}
              </Option>
            ))}
          </Select>
        ),
        formWrapperStyle: {
          marginBottom: '16px',
        }
      },

      {
        label: '排序',
        name: 'orderNum',
        autoRequired: true,
        isRow: true,
        renderTag: () => <InputNumber style={{ width: '100%' }} min={0} />,
        formWrapperStyle: {
          marginBottom: '6px',
        },
      },
    ],
  ]

  const getDetail = async () => {
    try {
      setLoading(true)
      setLoadingText('正在加载中，请稍后')
      const res = await getQuestionsInfo(id)
      if(res.questionType === 2){
        res.answer = res.answer.split(',')
      }
      res.options = JSON.parse(res.options)
      const formData = Object.assign(res, {})
      form.setFieldsValue(formData)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (id) getDetail()
  }, [])

  const onFinish = async (values: any) => {
    console.log(values)
    try {
      setLoading(true)
      setLoadingText('正在保存中，请稍后')
      let formData = _.cloneDeep(values)
      formData = Object.assign(values, {})
      if(formData.questionType === 2){
        formData.answer = formData.answer.join(',')
      }
      formData.options = JSON.stringify(formData.options)
      formData.testPaperId = Number(paperid)
      let res: any
      Object.assign(formData, { status: 1 })
      console.log(formData)
      if (id) {
        res = await updateQuestions(id, formData)
      } else {
        res = await addQuestions(formData)
      }
      if (res) {
        message.success(`${id ? '编辑' : '新增'}成功`)
        if (!id) localStorage.setItem('formValue', '')
        history.goBack()
      } else {
        message.error(`${id ? '编辑' : '新增'}失败`)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  return (
    <div className={`${styles.root} edit-page formReset`}>
      <Spin spinning={loading} tip={loadingText}>
        <Form
          form={form}
          scrollToFirstError
          onFinish={onFinish}
          className={styles.formWrapper}
        >
          <Form.Item
            name="name"
            label="试题标题"
            style={{
              marginBottom: 16,
            }}
            rules={[
              {
                required: true,
                message: '请输入试题标题',
              },
            ]}
          >
            <Input placeholder="请输入试题标题" />
          </Form.Item>
          <FormWrap fomrItems={contactInfoItems} />
          <Form.Item
            name="answerExplain"
            label="试题解析"
            style={{
              marginBottom: 16,
            }}
            rules={[{ required: true, message: '请输入试题解析' }]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item
            label="正确答案"
            shouldUpdate
            rules={[{ required: true, message: '请选择正确答案' }]}
            style={{
              marginBottom: 16,
            }}
          >
            {() => {
              return form.getFieldsValue().options ? (
                <Form.Item
                  name="answer"
                  rules={[{ required: true, message: '请选择正确答案' }]}
                >
                  <Select
                    placeholder="请选择状态"
                    {...(form.getFieldsValue().questionType === 2
                      ? { mode: 'multiple' }
                      : {})}
                  >
                    {form.getFieldsValue().options &&
                      form.getFieldsValue().options.map((i: any, index: any) =>
                        i && i.mark ? (
                          <Option key={index} value={i.mark}>
                            {i.mark}
                          </Option>
                        ) : (
                          ''
                        )
                      )}
                  </Select>
                </Form.Item>
              ) : (
                <Form.Item
                  name="success"
                  rules={[{ required: true, message: '请选择正确答案' }]}
                >
                  <Input placeholder="请输入正确答案" />
                </Form.Item>
              )
            }}
          </Form.Item>
          <div style={{ background: '#f0f0f0', padding: '12px 16px' }}>
            <Form.List
              name="options"
              initialValue={[
                {
                  mark: 'A',
                  item: '',
                },
                {
                  mark: 'B',
                  item: '',
                },
              ]}
              rules={[
                {
                  validator: async (_, options) => {
                    if (!options || options.length < 2) {
                      return Promise.reject(new Error('必须有两道题以上'))
                    }
                  },
                },
              ]}
            >
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map((field, index) => (
                    <Form.Item
                      key={field.key}
                      label={`试题选项${index + 1}`}
                      required={true}
                      style={{
                        marginBottom: 16,
                      }}
                    >
                      <div className="display-flex">
                        <Form.Item
                          {...field}
                          name={[field.name, 'mark']}
                          label="选项标识"
                          key={(field.key, 'mark')}
                          validateTrigger={['onBlur']}
                          style={{
                            width: '240px',
                          }}
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message: '请输入选项标识',
                            },
                          ]}
                        >
                          <Input placeholder="请输入选项标识" />
                        </Form.Item>

                        <Form.Item
                          {...field}
                          name={[field.name, 'item']}
                          key={(field.key, 'item')}
                          className="flex1"
                          label="选项文案"
                          validateTrigger={['onBlur']}
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message: '请输入选项文案',
                            },
                          ]}
                        >
                          <Input
                            placeholder="请输入试题选项"
                            style={{ width: '100%' }}
                          />
                        </Form.Item>
                        {fields.length > 1 ? (
                          <MinusCircleOutlined
                            style={{ width: 30, marginTop: 10 }}
                            className="dynamic-delete-button"
                            onClick={() => remove(field.name)}
                          />
                        ) : null}
                      </div>
                    </Form.Item>
                  ))}
                  {fields.length < 6 ? (
                    <Form.Item style={{ textAlign: 'center' }}>
                      <Button
                        type="primary"
                        onClick={() => add()}
                        style={{ width: '100%' }}
                        icon={<PlusOutlined />}
                      >
                        新增试题选项(最多支持6个选项)
                      </Button>
                      <Form.ErrorList errors={errors} />
                    </Form.Item>
                  ) : null}
                </>
              )}
            </Form.List>
          </div>
          <div style={{ textAlign: 'right', marginTop: 20 }}>
            <Button
              className={styles.closeBtn}
              style={{ marginRight: 12 }}
              onClick={() => history.goBack()}
            >
              取消
            </Button>
            <Button className={styles.saveBtn} type="primary" htmlType="submit">
              保存
            </Button>
          </div>
        </Form>
      </Spin>
    </div>
  )
}
export default OperateQuestions
