
import {IPublicConfigActions, SAVE_PUBLIC_CONFIG} from '../action'

const publicConfig = window.localStorage.getItem('publicConfig')
const initialState = {
  publicConfig: publicConfig ? JSON.parse(publicConfig) : {
    name: '作强消防运营平台',
  }
}

export interface IPublicConfig {
  publicConfig: {
    name: '作强消防运营平台';
  }
}

const reducer = (state = initialState, action: IPublicConfigActions & IPublicConfig) => {
  switch (action.type) {
    case SAVE_PUBLIC_CONFIG:
      window.localStorage.setItem('publicConfig', JSON.stringify(action.publicConfig))
      return Object.assign({}, state, {
        publicConfig: action.publicConfig,
      })
    default:
      return state
  }
}
export default reducer