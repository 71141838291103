import React, { FC} from 'react'
import { Form, Row, Col } from 'antd';
import styles from './FilterForm.module.scss';
// import { FooterBtns } from '@/components';
import { IBtns } from '@/components/FormWrap/components/FooterBtns/FooterBtns';
import { FormItemProps } from 'antd/lib/form/FormItem';

interface FilterFormProps {
	filterData?: any;
	onSearch?: (values: any) => void;
	btnArr?: IBtns[];
	warpStyle?: React.CSSProperties;
	pageHeadBtn?: React.ReactNode;
	renderFilterBtn?: React.ReactNode;
}

interface IFormItem extends FormItemProps {
	span?: number;
	style?: React.CSSProperties;
	name: string;
}

const FilterForm: FC<FilterFormProps> = (props) => {
	const { filterData = [], btnArr = [], warpStyle, pageHeadBtn, renderFilterBtn } = props;

  return (
    <div className={styles.filterBox} style={warpStyle}>
			<Row gutter={16} style={{ position: 'relative' }} className={styles.filterRow}>
				{
					filterData?.map((item:IFormItem) => {
						const { span, style, ...formItem } = item;
						return (
							<Col span={span ?? 6} style={style} key={item.name}>
								<Form.Item
									{...formItem}
								/>
							</Col>
						)
					})
				}
				{
					filterData?.length % 4 !== 0 && btnArr.length > 0 && <div className={styles.btnWarp}>
						<Col span="6" style={{ paddingLeft: 20 }}>
							{/* <FooterBtns btns={btnArr} containerStyle={{ margin: '0' }} /> */}
							{ renderFilterBtn }
						</Col>
						<div className={styles.headBtnBox}>{pageHeadBtn}</div>
					</div>
				}
			</Row>
			{
				filterData?.length % 4 === 0 && btnArr.length > 0 && (
					<Row justify="space-between">
						<Col span="6">
							{/* <FooterBtns btns={btnArr} containerStyle={{ margin: '0' }} /> */}
							{ renderFilterBtn }
						</Col>
						{
							pageHeadBtn
						}
					</Row>
				)
			}
		</div>
  )
}

export default FilterForm;
