import React, { FC, useState, useEffect } from 'react'
import { Button, Image, message } from 'antd';
import { useHistory } from 'react-router';
import moment from 'moment';
import styles from './CurriculumDetail.module.scss';
import { Buttons, Table, DetailItem } from '@/components';
import { IDetailItem } from '@/components/DetailItem/DetailItem';
import { getCurriculumInfo, deleteCurriculum, ICurriculumItem } from '@/api/curriculum/curriculum';
import { getParameters } from '@/utils/mainUtils';

// const { DeleteButton, EditButton, AddButton, DefaultButton, HideButton, CzButton } = Buttons

interface CurriculumDetailProps {
 
}

const CurriculumDetail: FC<CurriculumDetailProps> = (props) => {

	const detailArr: IDetailItem[] = [
		{
			title: '课程名称',
			dataIndex: 'name',
		},
		{
			title: '课程介绍',
			dataIndex: 'description',
		},
		{
			title: '课程视频',
			isRow: true,
			render: (record: any) => <video style={{ width: '100%' }} controls src={record.videoUrl} /> 
		},
	]
  const history = useHistory();
	const [detailData, setDetailData] = useState<any>();
	const { curriculumId } = getParameters();

	const getCurriculumDetail = async () => {
		const res = await getCurriculumInfo(curriculumId);
		setDetailData(res?.message ? res?.data : res);
	}

	useEffect(() => {
		getCurriculumDetail();
	}, [])

  return (
    <div className={`${styles.root} edit-page`}>
      <div className={styles.detailTitle}>基本信息</div>
			<DetailItem detailArr={detailArr} detailData={detailData} />
			{/* <Table
				tableStyle={{ padding: 0 }}
				titleStyle={{ marginTop: 20 }}
				showPagination={false}
				// getListFun={getUserCourse}
				formInitialValues={{ curriculumId }}
				columns={[
					{
						title: '二维码',
						dataIndex: 'avatarurl',
						render: (record: any) => <Image width={100} src={record.avatarurl} /> 
					},
					{
						title: '所在书籍页码',
						dataIndex: 'content',
					},
				]}
				pageTitle="课程视频列表"
			/> */}
			<div style={{ textAlign: 'right', marginTop: 20 }}>
				<Button className={styles.closeBtn} style={{ marginRight: 8 }} onClick={() => history.goBack()}>返回</Button>
				{/* <Button type="primary" htmlType="submit" onClick={() => history.goBack()}>确定</Button> */}
			</div>
    </div>
  )
}

export default CurriculumDetail;
