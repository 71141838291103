/*
 * @Description: 
 * @Autor: xingfutan
 * @Date: 2021-06-23 22:12:06
 * @LastEditors: xingfutan
 * @LastEditTime: 2021-08-12 16:51:33
 */
import $request from '@/request';

type ILogin = {
  userName: string;
  password: string;
};

export const postLogin = (data: ILogin): Promise<any> => {
  return $request.post('/manage/login', data)
}

export const getUser = (): Promise<any> => {
  return $request.get('/users')
}

type IGetUserList = {
  title?: string;
  status?: number;
	pageNo?: number;
	owner?: number;
	pageSize?: number;
	type?: number;
  nickName?: string;
};

export type IUserItem = {
  id?: number;
  owner?: number;
  sort?: number;
  status?: number;
  type?: number;
  addtime?: string;
  begintime?: string;
  content?: string;
  endtime?: string;
  headpic?: string;
  remark?: string;
  title?: string;
  updatetime?: string;
}

export interface IGetUserListRes {
  list: IUserItem[];
  total: number;
}

export const getUserList = (params: any): Promise<IGetUserListRes> => {
  return $request.get('/manage/user/list', {params})
}

export const getUserCourse= (params: any): Promise<IGetUserListRes> => {
  return $request.get(`/manage/user/course/list`, {params})
}

export const userRecharge = (data: any): Promise<any> => {
  return $request.post("/manage/user/recharge", data)
}

export const recommendUser = (data: any): Promise<any> => {
  return $request.put("/manage/user/recommend", data)
}

export const updateUserType = (data: any): Promise<any> => {
  return $request.put("/manage/user/type", data)
}

export const getUserInfo = (id: number): Promise<any> => {
  return $request.get(`/manage/user/info?id=${id}`)
}

export const updateIntegrals = (id: number): Promise<any> => {
  return $request.put(`/manage/user/integral/invalid?integralId=${id}`)
}

export const getRechargeList = (id: number): Promise<any> => {
  return $request.get(`/manage/user/recharge/list?userId=${id}`)
}

export const updateUserRecharge = (data: any): Promise<any> => {
  return $request.put("/manage/user/recharge", data)
}

export const deleteRecharge = (id: number): Promise<any> => {
  return $request.delete(`/manage/user/recharge?id=${id}`)
}

export const addIntegral = (data: any): Promise<any> => {
  return $request.post("/manage/user/integral", data)
}
