import $request from '@/request'

type IGetPaperList = {
  name?: string
  pageNo?: number
  pageSize?: number
}

export type IGetPaperItem<T> = {
  [x: string]: T
}

export interface IGetPaperListRes {
  list: IGetPaperItem<any>[]
  total: number
}

export interface addPaperTypes {
  name: string //课程标题
  status: number // 状态
  description?: string //描述
}

export interface updatePaperTypes extends addPaperTypes {
  id: string
  [x: string]: any
}

// 获取试卷列表
export const getPaperList = (params: IGetPaperList) => {
  return $request.get('/manage/test_paper/list', { params })
}
// 删除试卷
export const deletePaper = (id: number): Promise<any> => {
  return $request.delete(`/manage/test_paper/${id}`)
}

// 试卷详情
export const getPaperInfo = (id: number): any => {
  return $request.get(`/manage/test_paper/${id}`)
}
// 试卷更新
export const updatePaper = (id: string, data: updatePaperTypes): any => {
  return $request.put(`/manage/test_paper/${id}`, data)
}

/**
 *  创建试卷
 * @param data
 * @returns
 */
export const addPaper = (data: addPaperTypes): any => {
  return $request.post('/manage/test_paper', data)
}

/*********试题管理********/

// 获取试卷列表

interface IGetQuestionsTypes extends IGetPaperList {
  [x: string]: any
}

// 试题列表
export const getQuestionsList = (params: IGetQuestionsTypes) => {
  return $request.get('/manage/question/list', { params })
}

// 删除试题
export const deleteQuestions = (id: number): Promise<any> => {
  return $request.delete(`/manage/question/${id}`)
}

// 试题详情
export const getQuestionsInfo = (id: number): any => {
  return $request.get(`/manage/question/${id}`)
}

/**
 *  创建试题
 * @param data
 * @returns
 */
export const addQuestions = (data: any): any => {
  return $request.post('/manage/question/', data)
}

// 试题更新
export const updateQuestions = (id: string, data: any): any => {
  return $request.put(`/manage/question/${id}`, data)
}


// 统计
export const getPaperStatistics = (params:any): any => {
  return $request.get(`/manager/test_view/list`, {params})
}
