import React, { FC, useState, useEffect } from 'react'
import { Button } from 'antd';
import { useHistory } from 'react-router';
import styles from './SketchDetail.module.scss';
import { DetailItem } from '@/components';
import { IDetailItem } from '@/components/DetailItem/DetailItem';
import { getSketchInfo } from '@/api/sketch/index'
import { getParameters } from '@/utils/mainUtils';

interface SketchDetailProps {
 
}

const SketchDetail: FC<SketchDetailProps> = (props) => {

	const detailArr: IDetailItem[] = [
		{
			title: '图文课程名称',
			dataIndex: 'name',
		},
		{
			title: '图文课程介绍',
			dataIndex: 'description',
		},
		{
			title: '图文课程内容',
			isRow: true,
			render: (record: any) => <div dangerouslySetInnerHTML={{ __html: record.content }} />

		},
	]
  const history = useHistory();
	const [detailData, setDetailData] = useState<any>();
	const { id } = getParameters();

	const getSketchDetail = async () => {
		const res = await getSketchInfo(id);
		setDetailData(res?.message ? res?.data : res);
	}

	useEffect(() => {
		getSketchDetail();
	}, [])

  return (
    <div className={`${styles.root} edit-page`}>
      <div className={styles.detailTitle}>基本信息</div>
			<DetailItem detailArr={detailArr} detailData={detailData} />
			<div style={{ textAlign: 'right', marginTop: 20 }}>
				<Button className={styles.closeBtn} style={{ marginRight: 8 }} onClick={() => history.goBack()}>返回</Button>
			</div>
    </div>
  )
}

export default SketchDetail;
