import React, { FC, useState, useEffect } from 'react'
import { Space, Button, Image, message } from 'antd';
import { useHistory } from 'react-router';
import moment from 'moment';
import styles from './UserDetail.module.scss';
import { Table, DetailItem, FormModal } from '@/components';
import { getUserInfo, getUserCourse, userRecharge} from '@/api/user/user';
import { getParameters } from '@/utils/mainUtils';
import { getPaperStatistics } from '@/api/paper/index'


// const { DeleteButton, EditButton, AddButton, DefaultButton, HideButton, CzButton } = Buttons

interface UserDetailProps {
 
}

const UserDetail: FC<UserDetailProps> = (props) => {

	const detailArr = [
		{
			title: '昵称',
			dataIndex: 'nickName',
		},
		{
			title: '手机号',
			dataIndex: 'phone',
		},
		{
			title: '性别',
			dataIndex: 'gender',
			render: (record: any) => {
				const { gender } = record || {};
				return (
					<span>{gender === 2 ? '女性' : gender === 1 ? '男性' : '未知'}</span>
				)
			}
		},
		{
			title: '生日',
			render: (record: any) => record?.birthday && <span>{moment(record?.birthday).format('YYYY-MM-DD HH:mm:ss')}</span>
		},
		{
			title: '头像',
			render: (record: any) => <Image width={100} src={record.avatarUrl} /> 
		},
	]
  const history = useHistory();
	const [detailData, setDetailData] = useState<any>();
	const { userId } = getParameters();

	const getUserDetail = async () => {
		const res = await getUserInfo(userId);
		setDetailData(res?.message ? res?.data : res);
	}

	useEffect(() => {
		getUserDetail();
	}, [])

	
  return (
    <div className={`${styles.root} edit-page`}>
      <div className={styles.detailTitle}>基本信息</div>
			<DetailItem detailArr={detailArr} detailData={detailData} />
			<Table
				tableStyle={{ padding: 0 }}
				titleStyle={{ marginTop: 20 }}
				showPagination={false}
				getListFun={getUserCourse}
				formInitialValues={{ userId }}
				columns={[
					{
						title: '课程名称',
						dataIndex: 'name',
					},
					{
						title: '课程介绍',
						dataIndex: 'description',
					},
				]}
				pageTitle="课程列表"
			/>
      <Table
				tableStyle={{ padding: 0 }}
				titleStyle={{ marginTop: 20 }}
				showPagination={false}
				getListFun={getPaperStatistics}
				formInitialValues={{ userId }}
				columns={[
          {
            title: 'id',
            width: '60px',
            dataIndex: 'id',
          },
          {
            title: '试卷ID',
            dataIndex: 'testPaperId',
          },
          {
            title: '试卷二维码',
            dataIndex: 'testPaperId',
            render: (text: any, record: any) => (
              <Image
                width={60}
                src={`https://image-1308113567.cos.ap-beijing.myqcloud.com/image/code/%E8%AF%95%E5%8D%B7${record.testPaperId}.jpg`}
              />
            ),
          },
          {
            title: '创建时间',
            dataIndex: 'createTime',
            render: (text: any, record: any) =>
              record?.createTime && (
                <span>
                  {moment(record?.createTime).format('YYYY-MM-DD HH:mm:ss')}
                </span>
              ),
          },
          {
            title: '更新时间',
            dataIndex: 'updateTime',
            render: (text: any, record: any) =>
              record?.createTime && (
                <span>
                  {moment(record?.updateTime).format('YYYY-MM-DD HH:mm:ss')}
                </span>
              ),
          }
        ]}
				pageTitle="试卷统计"
			/>
			<div style={{ textAlign: 'right', marginTop: 20 }}>
				<Button className={styles.closeBtn} style={{ marginRight: 8 }} onClick={() => history.goBack()}>返回</Button>
				{/* <Button type="primary" htmlType="submit" onClick={() => history.goBack()}>确定</Button> */}
			</div>
    </div>
  )
}

export default UserDetail;
