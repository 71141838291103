
import {SAVE_USER_INFO, DELETE_USER_INFO, IUserInfoActions} from '../action'
import { IUserInfo } from '@/api/auth/auth'
import {signOut} from '@/utils/utils'

export interface IUserInfoState {
  userInfo: IUserInfo;
}

export interface ILoginState {
  login: {
    visible: boolean;
  }
}

const userInfo = window.localStorage.getItem('userInfo')
const initialState: IUserInfoState & ILoginState = {
  userInfo: userInfo ? JSON.parse(userInfo) : {},
  login: {
    visible: false
  }
}

const reducer = (state = initialState, action: IUserInfoActions & IUserInfoState) => {
  switch (action.type) {
    case SAVE_USER_INFO:
      window.localStorage.setItem('userInfo', JSON.stringify(action.userInfo))
      return Object.assign({}, state, {
        userInfo: action.userInfo,
      })
    case DELETE_USER_INFO:
      signOut()
      return Object.assign({}, state, {
        userInfo: {},
      })
    default:
      return state
  }
}
export default reducer