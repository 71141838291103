import $request from '@/request';

type IGetCurriculumList = {
  title?: string;
  status?: number;
	pageNo?: number;
	owner?: number;
	pageSize?: number;
	type?: number;
  categoryId?: string | number;
};

export type ICurriculumItem = {
  id: number;
  uuid: string;
  status: number;
  email: string;
  name: string;
  realname: string;
  password: string;
  lastlogin: string;
  addtime: string;
  roleid: number;
  updatetime: string;
}

export interface IGetCurriculumListRes {
  list: ICurriculumItem[];
  total: number;
}

export type IUpdateCurriculum = {
	id?: number;
  status?: number;
  email?: string;
  name?: string;
  realname?: string;
  password?: string;
}

export const getCurriculumList = (params: any): Promise<IGetCurriculumListRes> => {
  return $request.get('/manage/course/list', {params})
}

export const deleteCurriculum = (id: number): Promise<any> => {
  return $request.delete(`/manage/course/delete?id=${id}`)
}

export const hideCurriculum = (id: number): Promise<any> => {
  return $request.put(`/manage/course/hide?id=${id}`)
}

export const getCurriculumInfo = (id: number): Promise<any> => {
  return $request.get(`/manage/course/info?id=${id}`)
}

export const getCurriculumList1 = (params: any): Promise<IGetCurriculumListRes> => {
  return $request.get('/manage/course/list', {params})
}

export const updateCurriculum = (data: any): Promise<any> => {
  return $request.post('/manage/course/save', data)
}
