import React, { FC, useState, useEffect } from 'react'
import { Form, Button, Spin, message } from 'antd'
import FormWrap from '@/components/FormWrap/FormWrap'
import { useHistory } from 'react-router'
import _ from 'lodash'
import { getPaperInfo, updatePaper, addPaper } from '@/api/paper/index'
import styles from './OperatePaper.module.scss'
import { getParameters } from '@/utils/mainUtils'
interface OperatePaperProps {
  [x: string]: any
}
const OperatePaper: FC<OperatePaperProps> = (props) => {
  const history = useHistory()
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [loadingText, setLoadingText] = useState('')
  const { id } = getParameters()

  const contactInfoItems = [
    [
      {
        label: '名称',
        name: 'name',
        autoRequired: true,
      },
      {
        label: '描述',
        name: 'description',
        autoRequired: false,
      },
    ],
  ]

  const getDetail = async () => {
    try {
      setLoading(true)
      const res = await getPaperInfo(id)
      const formData = Object.assign(res, {})
      form.setFieldsValue(formData)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (id) getDetail()
  }, [])

  const onFinish = async (values: any) => {
    try {
      setLoading(true)
      setLoadingText('正在保存中，请稍后')
      let formData = _.cloneDeep(values)
      formData = Object.assign(values, {})
      let res: any
      Object.assign(formData, { status: 1 })
      if (id) {
        res = await updatePaper(id, formData)
      } else {
        res = await addPaper(formData)
      }
      if (res) {
        message.success(`${id ? '编辑' : '新增'}成功`)
        if (!id) localStorage.setItem('formValue', '')
        history.goBack()
      } else {
        message.error(`${id ? '编辑' : '新增'}失败`)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  return (
    <div className={`${styles.root} edit-page`}>
      <Spin spinning={loading} tip={loadingText}>
        <Form
          form={form}
          scrollToFirstError
          onFinish={onFinish}
          className={styles.formWrapper}
          initialValues={{
            newNum: 0,
            status: 1,
            adapt: 0,
          }}
        >
          <FormWrap fomrItems={contactInfoItems} />

          <div style={{ textAlign: 'right', marginTop: 20 }}>
            <Button
              className={styles.closeBtn}
              style={{ marginRight: 12 }}
              onClick={() => history.goBack()}
            >
              取消
            </Button>
            <Button className={styles.saveBtn} type="primary" htmlType="submit">
              保存
            </Button>
          </div>
        </Form>
      </Spin>
    </div>
  )
}
export default OperatePaper
