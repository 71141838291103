import React, {useState, FC, useEffect} from 'react'
import { Input, Spin, Empty } from 'antd';
import styles from './Charts.module.scss'
import {Row, Col} from 'antd'
import PieChart from '@/components/charts/Chart'

const Charts: FC = () => {

  let data = [
    {value: 335, name: '直接访问'},
    {value: 310, name: '邮件营销'},
    {value: 274, name: '联盟广告'},
    {value: 235, name: '视频广告'},
    {value: 400, name: '搜索引擎'}
  ]
  let total = 100
  let dates = ["04/15", "04/16", "04/17", "04/18", "04/19", "04/20", "04/21", "04/22"]
  const pieChartOption = {
    title: {
      text: '总收入',
      subtext: '¥' + total,
      subtextStyle: {
        fontSize: 17
      },
      x: 'center'
    },
    series: [
      {
        name: '总收入',
        type: 'pie',
        roseType: 'radius',
        radius: ['10%', '50%'],
        center: ['50%', '60%'],
        data,
        animationEasing: 'cubicInOut',
        animationDuration: 2600
      }
    ]
  }


  var formatted_data = []
  var legend = []
  for (var i = 0; i < data.length; i++) {
    var obj = {
      name: data[i].name,
      type: 'bar',
      stack: '总量',
      data: [data[i].value]
    }
    legend.push(obj.name)
    formatted_data.push(obj)
  }
  console.log('formatted_data=>', formatted_data)
  console.log('legend=>', legend)
  const barChartOption = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    legend: {
      data: legend
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      data: dates
    },
    yAxis: {
      type: 'value'
    },
    series: formatted_data
  }


  const lineChartOption = {
    title: {
      text: '新增会员数',
      subtext: '总新增数：' + total,
      subtextStyle: {
        fontSize: 17
      },
      x: 'center'
    },
    xAxis: {
      data: dates,
      boundaryGap: false,
      axisTick: {
        show: false
      }
    },
    grid: {
      left: '10%',
      right: '10%',
      bottom: '3%',
      containLabel: true
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross'
      },
      padding: [5, 10]
    },
    yAxis: {
      axisTick: {
        show: false
      }
    },
    series: [{
      name: '新增会员数',
      itemStyle: {
        normal: {
          color: '#FF005A',
          lineStyle: {
            color: '#FF005A',
            width: 2
          }
        }
      },
      smooth: false,
      type: 'line',
      data: data.map(i => i.value),
      animationDuration: 2800,
      animationEasing: 'cubicInOut'
    }]
  }

  const layoutConfig = {
    lg: { span: 24 },
    xl: { span: 8 }
  }

  return (
    <div className={styles.root}>
      <div className='title-container'>
        <h2 className="title-text">可视化报表</h2>
        {/* <Button onClick={this.addUser} type='primary'>新增</Button> */}
      </div>
      <Row gutter={[16, 16]}>
        <Col {...layoutConfig}>
          <PieChart setOption={pieChartOption}/>
        </Col>
        <Col {...layoutConfig}>
          <PieChart setOption={barChartOption}/>
        </Col>
        <Col {...layoutConfig}>
          <PieChart setOption={lineChartOption}/>
        </Col>
      </Row>
    </div>
  )
}

export default Charts;