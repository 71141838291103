/*
 * @Description: 
 * @Autor: xingfutan
 * @Date: 2021-06-23 22:12:06
 * @LastEditors: xingfutan
 * @LastEditTime: 2021-08-23 16:42:10
 */
import React, { FC } from 'react'
import { Form, Input } from 'antd'
import { Rule } from 'rc-field-form/lib/interface'
import styles from './FormWrap.module.scss'
import FooterBtns, { IBtns } from './components/FooterBtns/FooterBtns'

interface IFormWrapProps{
  fomrItems: Array<IFormItem | IFormItem[]>;
  btns?: IBtns[];
  modeStyle?: 'center';
  title?: React.ReactNode;
  titleStyle?: React.CSSProperties;
  subtitle?: React.ReactNode;
  subtitleStyle?: React.CSSProperties;
}

export interface IFormItem {
  label?: React.ReactNode;
  name?: any;
  rules?: Rule[];
  validateTrigger?: string;
  renderTag?: (item: IFormItem) => JSX.Element;
  placeholder?: string;
  autoRequired?: boolean;
  disabled?: boolean;
  initialValue?: any;
  type?: any;
  message?: string;
  change?: (v: string) => void;
  blur?: (v: string) => void;
  suffix?: React.ReactNode;
  node?: React.ReactNode;
  formWrapperStyle?: React.CSSProperties;
  inputClassName?: any;
  ref?: any;
  isRow?: boolean; // label是否与表单同一行
  isHalfRow?: boolean; // 是否宽度为50%
  inputStyle?: React.CSSProperties;
  isHide?: boolean;
  id?: string;
  messageType?: string; // 提示类型
}

const labelCol = { span: 24 };
const wrapperCol = { span: 24 };

export const renderFormItem = (item: IFormItem, key?: number) => {
  return (
    <Form.Item
      key={item.name}
      className={styles.formWrapper}
      label={item.label}
      name={item.name}
      rules={item.autoRequired ? [{ required: true, message: item.message || `请${item.messageType || '输入'}${item.label}！` }] : item.rules}
      validateTrigger={item.validateTrigger || 'onBlur'}
      initialValue={item.initialValue}
      style={item.formWrapperStyle}
      labelCol={item.isRow ? undefined : labelCol}
      wrapperCol={item.isRow ? undefined : wrapperCol}
      id={item.id}
    >
      {item.renderTag ? item.renderTag(item) : <Input
        disabled={item.disabled}
        placeholder={item.placeholder || `请输入${item.label}`}
        type={item.type}
        onChange={e => {item.change && item.change(e.target.value)}}
        onBlur={e => {item.blur && item.blur(e.target.value)}}
        suffix={item.suffix}
        className={item.inputClassName}
        ref={item.ref}
        style={item.inputStyle}
      />}
    </Form.Item>
  )
}

const FormWrap: FC<IFormWrapProps> = (props) => {

  return (
    <>
      {props.title && <div className={styles.title} style={props.titleStyle}>{props.title}</div>}
      {props.subtitle && <div className={styles.subtitle} style={props.subtitleStyle}>{props.subtitle}</div>}
      {
        props.fomrItems.map((item, index) => {
          return !Array.isArray(item) && item.node ? item.node : <div className={styles.rowItemsWrapper} style={{justifyContent: props.modeStyle || 'flex-start'}} key={`${index}`}>
            {Array.isArray(item) ? item.map((i, ind) => !i.isHide && <div key={`${ind}`} className={`${styles.halfItem}`}>{renderFormItem(i, ind)}</div>) : !item.isHide && <div className={item.isHalfRow ? styles.halfItem : styles.rowItem}>{renderFormItem(item, index)}</div>}
          </div>
        })
      }
      <FooterBtns btns={props.btns}/>
    </>
  )

}

export default FormWrap
