import _ from 'lodash';
import React, { FC} from 'react'
import styles from './DetailItem.module.scss';

export interface IDetailItem {
	title?: string;
	dataIndex?: string;
	value?: React.ReactNode;
	render?: (data: any) => React.ReactNode;
	isRow?: boolean;
}

interface DetailItemProps {
	detailArr: IDetailItem[]
	className?: string;
	labelStyle?: React.CSSProperties;
	valueStyle?: React.CSSProperties;
	detailData: any;
}

const DetailItem: FC<DetailItemProps> = (props) => {
	const { detailArr, className, labelStyle, valueStyle, detailData } = props;
	
  return (
    <div className={`${styles.detailWarp} ${className}`}>
			{
				detailArr.map((item, index) => (
					<div key={String(index)} className={`${styles.detailLi} ${item.isRow ? styles.rowLi : ''}`}>
						<div className={styles.label} style={labelStyle}>{item.title}：</div>
						<div className={styles.value} style={valueStyle}>
							{item.render ? detailData && !_.isEmpty(detailData) ? item.render(detailData) : '-' : item.dataIndex && detailData && !_.isEmpty(detailData) ? detailData[item.dataIndex] : '-'}
						</div>
					</div>
				))
			}
		</div>
  )
}

export default DetailItem;
