

// UserInfo
export const SAVE_USER_INFO = 'SAVE_USER_INFO'

export const DELETE_USER_INFO = 'DELETE_USER_INFO'

// PublicConfig
export const SAVE_PUBLIC_CONFIG = 'SAVE_PUBLIC_CONFIG'

export interface IUserInfoActions {
  type: typeof SAVE_USER_INFO & typeof DELETE_USER_INFO;
}

export interface IPublicConfigActions {
  type: typeof SAVE_PUBLIC_CONFIG;
}