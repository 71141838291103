import React, { FC, useState, useRef } from 'react'
import { Space, Input, message, Form, Row, Col, Button, Tooltip } from 'antd'
import { useHistory } from 'react-router'
import styles from './QuestionsList.module.scss'
import { Popconfirm, Buttons, Table } from '@/components'
import { getQuestionsList, deleteQuestions } from '@/api/paper/index'
import { DefaultButton } from '@/components/Buttons/Buttons'
import { getParameters } from '@/utils/mainUtils'
import { EnterOutlined } from '@ant-design/icons'

const { DeleteButton, EditButton, AddButton } = Buttons

interface QuestionsListProps {}

const QuestionsList: FC<QuestionsListProps> = (props) => {
  const history = useHistory()
  const [isUpdate, setIsUpdate] = useState(false)
  const [form] = Form.useForm()
  const childRef: any = useRef()
  const { id, name } = getParameters()

  const setStorage = () => {
    childRef?.current?.setFilterConditions()
  }

  const columns = [
    {
      title: 'id',
      width: '60px',
      dataIndex: 'id',
    },
    {
      title: '试题标题',
      dataIndex: 'name',
    },
    {
      title: '试题类型',
      width: '90px',
      dataIndex: 'questionType',
      render: (text: any, record: any) => (
        <span>{record.questionType === 1 ? '单选' : '多选'}</span>
      ),
    },
    {
      title: '排序',
      width: '90px',
      dataIndex: 'orderNum',
    },
    {
      title: '正确答案',
      width: '90px',
      dataIndex: 'answer',
    },
    {
      title: '解析',
      ellipsis: true,
      dataIndex: 'answerExplain',
      render: (text: any, record: any) => (
        <Tooltip placement="bottomLeft" title={text}>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: '操作',
      width: '260px',
      key: 'status',
      render: (text: any, record: any) => (
        <div>
          <Space size="small">
            <EditButton
              onClick={() => {
                setStorage()
                history.push(
                  `/questions/list/edit?paperid=${id}&id=${record.id}`
                )
              }}
            />
            <Popconfirm
              title="确认删除吗？"
              onConfirm={async () => {
                onDelete(record.id)
              }}
            >
              <DeleteButton />
            </Popconfirm>
            <DefaultButton
              btnText="试题预览"
              onClick={() =>
                history.push(`/questions/list/detail?id=${record.id}`)
              }
              style={{ width: 88 }}
            />
          </Space>
        </div>
      ),
    },
  ]

  const filterData = [
    {
      name: 'name',
      label: '试题标题',
      children: <Input placeholder="请输入试题标题" />,
    },
    // {
    //   name: 'status',
    //   label: '状态',
    //   children: (
    //     <Select placeholder="请选择状态">
    //       {statusOptions.map((i, index) => (
    //         <Option key={index} value={i.value}>
    //           {i.label}
    //         </Option>
    //       ))}
    //     </Select>
    //   ),
    // },
  ]
  const onDelete = async (id: number) => {
    try {
      const res = await deleteQuestions(id)

      if (res === 0) {
        message.success('删除成功')
        setIsUpdate(true)
        // } else {
        // 	message.error('删除失败');
      }
    } catch (error) {}
  }

  return (
    <div className={styles.root}>
      <div
        className="FilterForm_filterBox__pOZXf"
        style={{ padding: '15px 20px' }}
      >
        <Row>
          <Col span={16}>
            <span>
              当前所属试卷id:
              <b style={{ color: '#5767FA', marginLeft: 5 }}>{id}</b>
            </span>
            <span style={{ marginLeft: '40px' }}>
              当前所属试卷名称:
              <b style={{ color: '#5767FA', marginLeft: 5 }}>
                {decodeURIComponent(name)}
              </b>
            </span>
          </Col>
          <Col span={8} style={{ textAlign: 'right' }}>
            <Button
              type="primary"
              shape="round"
              className={styles.returnBtn}
              icon={<EnterOutlined />}
              onClick={() => history.goBack()}
            >
              返回试卷
            </Button>
          </Col>
        </Row>
      </div>
      <Table
        columns={columns}
        filterData={filterData}
        parentForm={form}
        getListFun={getQuestionsList}
        formInitialValues={{ testPaperId: id }}
        isUpdate={isUpdate}
        setIsUpdate={setIsUpdate}
        cRef={childRef}
        pageHeadBtn={
          <AddButton
            className={styles.addBtn}
            style={{ marginBottom: 0 }}
            name="新增试题"
            onClick={() => history.push(`/questions/list/add?paperid=${id}`)}
          />
        }
      />
    </div>
  )
}

export default QuestionsList
