import React, { FC, useState, useEffect } from 'react'
import { Form, Button, Spin, message } from 'antd'
import FormWrap from '@/components/FormWrap/FormWrap'
import { useHistory } from 'react-router'
import _ from 'lodash'
import { getSketchInfo, updateSketch, addSketch } from '@/api/sketch/index'
import styles from './OperateSketch.module.scss'
import { getParameters } from '@/utils/mainUtils'
import TinyMce from '@/components/TinyMce/TinyMce'
interface OperateSketchProps {
  [x: string]: any
}
// const { Option } = Select

// const statusOptions = [
//   { label: '上架', value: 1 },
//   { label: '下架', value: 0 },
// ]
const OperateSketch: FC<OperateSketchProps> = (props) => {
  const history = useHistory()
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [content, setContent] = useState('')
  const [contentInfo, setContentInfo] = useState('')
  const [loadingText, setLoadingText] = useState('')
  const { id } = getParameters()

  const contactInfoItems = [
    [
      {
        label: '标题',
        name: 'name',
        autoRequired: true,
      },
      {
        label: '描述',
        name: 'description',
        autoRequired: false,
      },
      // {
      //   label: '状态',
      //   name: 'status',
      //   autoRequired: true,
      //   isHalfRow: true,
      //   renderTag: () => (
      //     <Select placeholder="请选择状态">
      //       {statusOptions.map((i, index) => (
      //         <Option key={index} value={i.value}>
      //           {i.label}
      //         </Option>
      //       ))}
      //     </Select>
      //   ),
      // },
    ],
  ]

  const getDetail = async () => {
    try {
      setLoading(true)
      setLoadingText('正在加载中，请稍后')
      const res = await getSketchInfo(id)
      const formData = Object.assign(res, {})
      setContent(res.content)
      form.setFieldsValue(formData)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (id) getDetail()
  }, [])

  const onFinish = async (values: any) => {
    try {
      setLoading(true)
      setLoadingText('正在保存中，请稍后')
      let formData = _.cloneDeep(values)
      formData = Object.assign(values, {})
      let res: any
      Object.assign(formData, { content:contentInfo },{status:1})
      if (id) {
        res = await updateSketch(id,formData)
      } else {
        res = await addSketch(formData)
      }
      if (res) {
        message.success(`${id ? '编辑' : '新增'}成功`)
        if (!id) localStorage.setItem('formValue', '')
        history.goBack()
      } else {
        message.error(`${id ? '编辑' : '新增'}失败`)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  // 监听富文本组件内容输入
  const getTinyMceContent = (value: string) => {
    setContentInfo(value)
  }
  return (
    <div className={`${styles.root} edit-page`}>
      <Spin spinning={loading} tip={loadingText}>
        <Form
          form={form}
          scrollToFirstError
          onFinish={onFinish}
          className={styles.formWrapper}
          initialValues={{
            newNum: 0,
            status: 1,
            adapt: 0,
          }}
        >
          <FormWrap fomrItems={contactInfoItems} />
          <TinyMce
            content={content}
            getTinyMceChange={getTinyMceContent}
          ></TinyMce>

          <div style={{ textAlign: 'right', marginTop: 20 }}>
            <Button
              className={styles.closeBtn}
              style={{ marginRight: 12 }}
              onClick={() => history.goBack()}
            >
              取消
            </Button>
            <Button className={styles.saveBtn} type="primary" htmlType="submit">
              保存
            </Button>
          </div>
        </Form>
      </Spin>
    </div>
  )
}
export default OperateSketch
