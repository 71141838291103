/*
 * @Description: 
 * @Autor: xingfutan
 * @Date: 2021-06-23 22:12:06
 * @LastEditors: xingfutan
 * @LastEditTime: 2021-08-23 16:19:11
 */
import axios, { AxiosInstance } from 'axios'
import { message } from 'antd'
import {signOut} from '@/utils/utils'

export const apiUrl = 'http://152.136.113.71:10104/edu';
export const productionApiUrl = 'https://api.zuoqiangxiaofang.com/edu';

// export const baseURL =  `api/`;
export const baseURL =  `${process.env.REACT_APP__ENV === 'production' ? productionApiUrl : apiUrl}/`;
// export const baseURL =  process.env.NODE_ENV === 'development' ? 
export const getToken = () => {
  const tokenItem = document.cookie.split(';').find(item => ~item.substring(0, 10).indexOf('token'))
  const token = tokenItem && tokenItem.slice(tokenItem.indexOf('=') + 1)
  // if (token) {
  //   return 'Bearer ' + token
  // }
  return  token || ''
}

let params = {
  baseURL: baseURL,
  timeout: 100000,
  headers: {
  }
}

let instance: AxiosInstance = axios.create(params)

instance.interceptors.request.use(function (config) {
  const token = getToken()
  token && (config.headers['Authorization'] = token)
  // console.log('config', config)
  // config.url = config.url
  return config;
}, function (error) {
  message.error('网络异常！')
  return Promise.reject(error);
})

instance.interceptors.response.use(function (response) {
  const data = response?.data
  return data && data.data ? data.data : data;
}, function (error) {
  const errorInfo = error?.response?.data
  try {
    if (errorInfo.code === 403) {
      signOut()
      setTimeout(() => {
        window.location.reload()
      }, 2000);
    }
    if (errorInfo && errorInfo.message) {
      message.error(errorInfo.message)
    } else {
      message.error('网络异常！')
    }
  } catch (error) {
    message.error('网络异常！请刷新后重试！')
  }
  return Promise.reject(errorInfo);
})

export default instance
