import React, { FC, useEffect } from 'react'
import styles from './chart.module.scss'
// 引入 ECharts 主模块
import * as echarts from 'echarts/lib/echarts';
import 'echarts/theme/macarons';
import 'echarts/lib/chart/bar'
import 'echarts/lib/chart/line'
import 'echarts/lib/chart/pie'
import 'echarts/lib/chart/map'
import 'echarts/lib/chart/radar'
import 'echarts/lib/chart/scatter'
import 'echarts/lib/chart/effectScatter'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/polar'
import 'echarts/lib/component/geo'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/title'
import 'echarts/lib/component/visualMap'
import { debounce } from '@/utils/utils'

interface IEchartsProps {
  setOption: any;
}

const Echarts: FC<IEchartsProps> = (props) => {

  let __resizeHanlder: any
  let chart: any
  let chartEl: any
  useEffect(() => {
    initChartDom()
    __resizeHanlder = debounce(() => {
      if (chart) {
        chart.resize()
      }
    }, 100)
    window.addEventListener('resize', __resizeHanlder)
    return () => {
      window.removeEventListener('resize', __resizeHanlder)
      if (!chart) {
        return
      }
      chart.dispose()
      chart = null
    }
  }, [])


  const initChartDom = () => {
    chart = echarts.init(chartEl, 'macarons')
    chart.setOption(props.setOption || {})
    setTimeout(() => {
      chart.resize()
    }, 0)
  }

  return (
    <div className={`${styles['chart-container']}`}>
      <div className={styles['chart-canvas']} ref={el => (chartEl = el)}></div>
    </div>
  );
}

export default Echarts;