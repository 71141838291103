
export function signOut() {
  window.document.cookie = `token=1;expires=${new Date(0).toGMTString()}`
  window.localStorage.removeItem('userInfo')
}

export function debounce (fn, delay = 100) {
  let timer = null
  return function (...args) {
    timer && clearTimeout(timer)
    timer = setTimeout(() => {
      fn.apply(this, args)
    }, delay)
  }
}

export function throttle (fn, delay = 100) {
  let previous = 0
  return function(...args) {
    let now = +new Date()
    if (now - previous > delay) {
      previous = now
      fn.apply(this, args)
    }
  }
}

  // const controls = [
  //   'undo', 'redo', 'separator',
  //   'font-size', 'line-height', 'letter-spacing', 'separator',
  //   'text-color', 'bold', 'italic', 'underline', 'strike-through', 'separator',
  //   'superscript', 'subscript', 'remove-styles', 'emoji',  'separator', 'text-indent', 'text-align', 'separator',
  //   'headings', 'list-ul', 'list-ol', 'blockquote', 'code', 'separator',
  //   'link', 'separator', 'hr', 'separator',
  //   'media', 'separator',
  //   'clear'
  // ]
const controls = [
  'undo', 'redo', 'remove-styles', 'separator',
  'font-size', 'bold', 'separator',
  'text-align', 'separator',
  'media', 'separator',
  'clear'
]

export const BraftEditorConfig = {
  controls,
  media: {
    // 配置允许插入的外部媒体的类型
    externals: {
      image: true,
      video: false,
      audio: false,
      embed: false
    },
    // 配置允许上传的媒体类型
    accepts: {
      image: true,
      video: false,
      audio: false,
    },
    pasteImage: true
  }
}