import React, { FC, useState, useRef } from 'react'
import { Space, Input, Select, message, Form } 
from 'antd';
import { useHistory } from 'react-router';
// import moment from 'moment';
import styles from './CurriculumList.module.scss';
import { Popconfirm, Buttons, Table } from '@/components';
import { getCurriculumList, deleteCurriculum, ICurriculumItem } from '@/api/curriculum/curriculum';
import { DefaultButton } from '@/components/Buttons/Buttons';

const { DeleteButton, EditButton, AddButton } = Buttons

const { Option } = Select;

interface CurriculumListProps {
 
}

const CurriculumList: FC<CurriculumListProps> = (props) => {
  const history = useHistory();
	const [isUpdate, setIsUpdate] = useState(false);
	const [form] = Form.useForm();
	const childRef: any = useRef();

	const onDelete = async (id: number) => {
		try {
			const res = await deleteCurriculum(id);
			if (res) {
				message.success('删除成功');
				setIsUpdate(true);
			} else {
				message.error('删除失败');
			}
		} catch (error) {
			
		}
	}

	const setStorage = () => {
		childRef?.current?.setFilterConditions();
	}

	const columns = [
		{
			title: '课程对应书籍名称',
			dataIndex: 'name',
		},
		{
			title: '视频URL',
			dataIndex: 'videoUrl',
		},
		{
			title: '操作',
			key: 'status',
			render: (text: any, record: any) => (
				<div>
					<Space size="small">
						{/* <EditButton
							onClick={() => {
								setStorage();
								history.push(`/curriculum/list/edit?id=${record.id}`)
							}}
						/>
						<Popconfirm
							title="确认删除吗？"
							onConfirm={async () => {
								onDelete(record.id)
							}}
						>
							<DeleteButton />
						</Popconfirm> */}
						<DefaultButton btnText="课程详情" onClick={() => history.push(`/curriculum/list/detail?curriculumId=${record.id}`)} style={{ width: 88 }} />
					</Space>
				</div>
			)
		},
	];

	const filterData = [
		{name: 'curriculumName', label: '课程名称', children: <Input placeholder='请输入名称' />},
	]
	const formInitialValues = { adapt: 'all' }
  return (
    <div className={styles.root}>
      <Table
				columns={columns}
				// filterData={filterData}
				parentForm={form}
				formInitialValues={formInitialValues}
				getListFun={getCurriculumList}
				isUpdate={isUpdate}
				setIsUpdate={setIsUpdate}
				cRef={childRef}
				// pageHeadBtn={
				// 	<AddButton
				// 		className={styles.addBtn}
				// 		style={{ marginBottom: 0 }}
				// 		name="新增课程"
				// 		onClick={() => history.push("/curriculum/list/edit")}
				// 	/>
				// }
			/>
    </div>
  )
}

export default CurriculumList;
