import React, { FC, useRef } from 'react'
import styles from './TinyMce.module.scss'
import _ from 'lodash'
import { Editor } from '@tinymce/tinymce-react'
import axios from 'axios'
import { baseURL, getToken } from '@/request'
import { getUUID4 } from '@/utils/mainUtils'
import {
  plugins,
  fontSize,
  toolbar,
  menu,
  enubar,
  codesampleLanguages,
} from './TinyMceConfig'

interface TinyMceComponentProps {
  content?: any
  getTinyMceChange: (e: any) => void
  [x: string]: any
}
const defaultFun = () => {}

const TinyMceComponent: FC<TinyMceComponentProps> = (props) => {
  const { content, getTinyMceChange = defaultFun } = props
  const editorRef: any = useRef(null)

  const handleEditorChange = (content: any, editor: any) => {
    getTinyMceChange(content)
  }

  return (
    <div className={styles.root}>
      <Editor
        onInit={(evt, editor) => (editorRef.current = editor)}
        initialValue={content}
        init={{
          height: 600,
          language_url: '/tinymce/langs/zh-Hans.js',
          language: 'zh-Hans',
          plugins: plugins,
          toolbar: toolbar,
          font_size_formats: fontSize,
          promotion: false,
          menu: menu,
          enubar: enubar,
          codesample_languages: codesampleLanguages,
          images_upload_handler: (blobInfo: any, progress: any) =>
            new Promise((resolve, reject) => {
              console.log(blobInfo.blob())
              // if (blobInfo.blob().size / 1024 / 1024 < 2) {
              //   var reader = new FileReader()
              //   reader.readAsDataURL(blobInfo.blob())
              //   reader.onload = function () {
              //     resolve(this.result as any)
              //   }
              // } else {
                const url = `${baseURL}img/upload`
                const params = new FormData()
                params.append('IMG_Raw', blobInfo.blob())
                params.append('IMG_Name', `${getUUID4()}${blobInfo.blob().name}`)

                const config = {
                  headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: getToken(),
                    appId: localStorage.getItem('appId') || 'paishanshi',
                  },
                  // withCredentials: true,
                }

                axios
                  .post(url, params, config)
                  .then((res) => {
                    if (res.data) {
                      console.log(res.data.substring(6))
                      resolve(res.data.substring(6)) // 上传成功，在成功函数里填入图片路径
                    } else {
                      reject('HTTP Error: 上传失败' + res.data.errMessage)
                      return
                    }
                  })
                  .catch(() => {
                    reject('上传出错，服务器开小差了呢')
                    return
                  })
                // reject({
                //   message: '上传失败，图片大小请控制在 2M 以内',
                //   remove: true,
                // })
                // return
              // }
            }),
          // images_upload_handler: imagesUploadHandler,
        }}
        onEditorChange={_.debounce(handleEditorChange, 300)}
      />
      {/* <button onClick={log}>Log editor content</button> */}
    </div>
  )
}

export default TinyMceComponent
