import React, {Component, useEffect} from 'react'
import {Route, Switch, HashRouter} from 'react-router-dom'
import './App.css'
import Homepage from './pages/index'
import Login from './pages/Login/Login'
import { DELETE_USER_INFO } from '@/redux/action'
import {useMappedState, useDispatch} from "redux-react-hook";
import {IState} from '@/redux/interface'
import { useHistory, useLocation } from 'react-router';
const AppRoute = () => {

  const userInfo = useMappedState((state: IState) => state.UserInfoReducer.userInfo);

  const dispatch = useDispatch();
  const history = useHistory();
  // const location = useLocation()

  useEffect(() => {
    const cookies = document.cookie
    const userInfo = window.localStorage.getItem('userInfo')
    if (!cookies || !userInfo) {
      dispatch({ type: DELETE_USER_INFO })
      history.replace('/login')
    }
  }, [])

  useEffect(() => {
    try {
      // window.scrollTo(0, 0)
    } catch (error) {
      console.log('route error', error)
    }
  })

  return (
    <HashRouter>
      <Switch>
        <Route exact path='/login' component={Login}></Route>
        <Route path='/' component={Homepage}></Route>
      </Switch>
    </HashRouter>
  )
}

export default AppRoute