import React, { FC, useState, useRef } from 'react'
import { Space, Input, Form, Image } from 'antd'
import { useHistory } from 'react-router'
import moment from 'moment'
import styles from './PaperList.module.scss'
import { Buttons, Table } from '@/components'
import { getPaperList } from '@/api/paper/index'

const { DefaultButton, EditButton, AddButton } = Buttons

interface PaperListProps {}

const PaperList: FC<PaperListProps> = (props) => {
  const history = useHistory()
  const [isUpdate, setIsUpdate] = useState(false)
  const [form] = Form.useForm()
  const childRef: any = useRef()

  const setStorage = () => {
    childRef?.current?.setFilterConditions()
  }

  const columns = [
    {
      title: 'id',
      width: '60px',
      dataIndex: 'id',
    },
    {
      title: '试卷名称',
      dataIndex: 'name',
    },
    {
      title: '试卷二维码',
      dataIndex: 'name',
      render: (text: any, record: any) => (
        <Image
          width={60}
          src={`https://image-1308113567.cos.ap-beijing.myqcloud.com/image/code/%E8%AF%95%E5%8D%B7${record.id}.jpg`}
        />
      ),
    },
    {
      title: '描述',
      dataIndex: 'description',
    },
    {
      title: '更新时间',
      dataIndex: 'updateTime',
      render: (text: any, record: any) =>
        record?.createTime && (
          <span>
            {moment(record?.updateTime).format('YYYY-MM-DD HH:mm:ss')}
          </span>
        ),
    },
    {
      title: '操作',
      key: 'status',
      width: '220px',
      render: (text: any, record: any) => (
        <div>
          <Space size="small">
            <EditButton
              onClick={() => {
                setStorage()
                history.push(`/paper/list/edit?id=${record.id}`)
              }}
            />
          </Space>
          <DefaultButton
            btnText="管理试卷内试题"
            onClick={() =>
              history.push(
                `/questions/list?id=${record.id}&name=${record.name}`
              )
            }
            style={{ width: 108, marginLeft: 12, color: '#1890ff' }}
          />
        </div>
      ),
    },
  ]

  const filterData = [
    {
      name: 'name',
      label: '试卷名称',
      children: <Input placeholder="请输入试卷名称" />,
    },
  ]

  return (
    <div className={styles.root}>
      <Table
        columns={columns}
        filterData={filterData}
        parentForm={form}
        getListFun={getPaperList}
        isUpdate={isUpdate}
        setIsUpdate={setIsUpdate}
        cRef={childRef}
        pageHeadBtn={
          <AddButton
            className={styles.addBtn}
            style={{ marginBottom: 0 }}
            name="新增试卷"
            onClick={() => history.push('/paper/list/add')}
          />
        }
      />
    </div>
  )
}

export default PaperList
