import React, { FC } from 'react'
import { Form, Button } from 'antd'
import { RightOutlined } from '@ant-design/icons';
import styles from './FooterBtns.module.scss'

export interface IBtns {
  hide: boolean;
  title: string;
  click?: () => void;
  submit?: true;
  loading?: boolean;
  type?: "link" | "text" | "default" | "ghost" | "primary" | "dashed";
  className?: any;
  isHaveArrow?: boolean;
  isLeft?: boolean;
}

interface IFooterBtnsProps{
  btns?: IBtns[];
  containerStyle?: React.CSSProperties;
}

const FooterBtns: FC<IFooterBtnsProps> = (props) => {

  return (
    <>
      {props.btns && <Form.Item>
        <div className={styles.footerBtnWrapper} style={props.containerStyle}>
          {props.btns.map((btn, index) => {
            return btn.hide ? null : <Button
              key={index}
              htmlType={btn.submit ? "submit" : undefined}
              type={btn.type}
              loading={btn.loading}
              className={`${styles.footerBtn} ${btn.className} ${btn.isHaveArrow ? styles.arrowBtn : ''} ${btn.isLeft ? styles.leftBtn : ''}`}
              onClick={() => {btn.click && btn.click()}}
            >
              {btn.title}
              {
                btn.isHaveArrow && (
                  <span className={styles.arrow}>
                    <span className={styles.line} />
                    <RightOutlined className={styles.arrowIcon} />
                  </span>
                )
              }
            </Button>
          })}
        </div>
      </Form.Item>}
    </>
  )

}

export default FooterBtns
