import React, { FC, useState, useEffect, useMemo, useImperativeHandle, forwardRef } from 'react'
import { Table, Pagination, Form, Space } from 'antd';
import styles from './Table.module.scss';
import moment from 'moment';
import { FilterForm, Buttons } from '@/components';
import { IBtns } from '@/components/FormWrap/components/FooterBtns/FooterBtns';
import _ from 'lodash';

const { SearchButton, ResetButton } = Buttons;

interface TableComponentProps {
	filterData?: any;
	columns: any;
	pageTitle?: string;
	pageHeadBtn?: React.ReactNode;
  titleStyle?: React.CSSProperties;
	formClassName?: string;
	showPagination?: boolean;
	getListFun?: (formData?: any) => any;
	btnArr?: IBtns[];
  paginationStyle?: React.CSSProperties;
	formInitialValues?: any;
	dataSource?: any;
	tableStyle?: React.CSSProperties;
	isUpdate?: boolean; // 是否重新请求
	setIsUpdate?: (flag: boolean) => void; // 请求完毕后修改状态
	isShowAddBtn?: boolean;
	tabsNode?: React.ReactNode;
	parentForm?: any;
	cRef?: any; // 从父组件接收的ref
}

// const arr = [
// 	{ name: '管理员', time: '5050-01-28', id: '1' }
// ]

const TableComponent: FC<TableComponentProps> = (props) => {
	const formatText = "YYYY-MM-DD"
	const [form] = Form.useForm();
	const currentForm = props?.parentForm || form;
	const defaultBtn: IBtns[] = [
		{
			title: '查询',
			hide: false,
			type: 'primary',
			submit: true,
			className: styles.btnItem
		},
		{
			title: "重置",
			hide: false,
			className: styles.btnItem,
			click: () => {
				currentForm.resetFields();
				getList(1, 50, formInitialValues);
			},
		}
	]
	const {
		columns,
		filterData = [],
		pageTitle,
		pageHeadBtn,
		titleStyle,
		formClassName,
		showPagination = true,
		getListFun,
		paginationStyle,
		btnArr = defaultBtn,
		formInitialValues,
		dataSource,
		tableStyle,
		isUpdate,
		setIsUpdate,
		isShowAddBtn,
		tabsNode,
		cRef,
		...tableProps
	} = props;
  const [tableData, setTableData] = useState([]);
  const [tableLoading, setTableLoading] = useState<boolean>(false);

	const returnFormData = (formObj: any) => {
		let values = _.cloneDeep(formObj);
		const initialValues = _.cloneDeep(formInitialValues);
		if (initialValues) values = Object.assign(initialValues, values)
		return values;
	}

  const onShowSizeChange = (current?: number, pageSize?: number) => {
    if (current) {
      // window.scrollTo(0, 0)
			const formObj = localStorage.getItem('formValue');
			if (formObj) {
				const newObject = JSON.parse(formObj);
				delete newObject.pageNo;
				delete newObject.pageSize;
				localStorage.setItem('formValue', _.isEmpty(newObject) ? '' : JSON.stringify(newObject))
			}
			const values = returnFormData(currentForm.getFieldsValue())
			getList(current, pageSize, returnForm(values));
    }
  };

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 50,
    pageSizeOptions: ['10', '20', '50', '100'],
    total: 10,
		showTotal: (total: any) => `共 ${total} 条`,
    // onChange: handlePaginationChange,
    showSizeChanger: true,
    // onShowSizeChange: handlePaginationShowSizeChange
  });

	useImperativeHandle(cRef, () => ({
		// setFilterConditions 就是暴露给父组件获取过滤条件的方法，主要用于做过滤条件的缓存，父组件可以通过childRef.current.setFilterConditions调用
		setFilterConditions: () => {
			const obj: any = {};
			const { current, pageSize } = pagination;
			const filterObj = { pageNo: current, pageSize, ...currentForm.getFieldsValue() }
			for(const key in filterObj) {
				if (filterObj[key]) obj[key] = filterObj[key];
			}
			localStorage.setItem('formValue', JSON.stringify(obj))
		}
	}));

	const getList = async (pageNo: number = 1, pageSize: number = 50, values?: any) => {
		if (!getListFun) {
			setPagination(Object.assign(pagination, {
				current: 1,
				pageSize: 50,
				total: 0,
			}))
			setIsUpdate?.(false);
			setTableData([])
			return;
		}
		try {
			setTableLoading(true);
			const obj: any = {};
			if (values) {
				for (const key in values) {
					obj[key] = values[key] === 'all' ? undefined : values[key]
				}
			}
			let newPageNo = pageNo;
			let newPageSize = pageSize;
			const formData: any = Object.assign({ pageNo: newPageNo, pageSize: newPageSize }, obj ?? {});
			const formObj = localStorage.getItem('formValue')
			if (formObj) {
				const newObject = JSON.parse(formObj)
				newPageNo = newObject?.pageNo ? Number(newObject?.pageNo) : pageNo;
				newPageSize = newObject?.pageSize ? Number(newObject?.pageSize) : pageSize;
				// const newObj: any = {}
				// for(const key in newObject) {
				// 	newObj[key] = decodeURIComponent(newObject[key])
				// }
				currentForm.setFieldsValue(newObject);
				Object.assign(formData, newObject)
			}
			for(const key in formData) {
				formData[key] = ['all', 'undefined'].includes(decodeURIComponent(formData[key])) ? undefined : decodeURIComponent(formData[key])
			}
			const { list, total } = await getListFun(formData);
			setTableData(list ?? [])
			const realPagination = Object.assign(pagination, {
				current: newPageNo,
				total,
				pageSize: newPageSize,
			});
			setPagination(realPagination);
			setTableLoading(false);
			setIsUpdate?.(false);
		} catch (error) {
			setTableLoading(false);
			setIsUpdate?.(false);
		}
	}

	const returnForm = (values: any) =>{
		const { rangePicker, akRangePicker } = values;
		let formData = {...values};
		if (rangePicker && rangePicker.length) {
			formData = Object.assign(formData, {
				beginTimeStr: moment(rangePicker[0]).format(formatText),
				endTimeStr: moment(rangePicker[1]).format(formatText),
			})
			delete formData.rangePicker;
		}
		if (akRangePicker && akRangePicker.length) {
			formData = Object.assign(formData, {
				startTime: moment(akRangePicker[0]).format('YYYY-MM-DD HH:mm:ss'),
				endTime: moment(akRangePicker[1]).format('YYYY-MM-DD HH:mm:ss'),
			})
			delete formData.akRangePicker;
		}
		return formData;
	}

  useEffect(() => {
		getList(1, 50, formInitialValues);
	}, []);
	
	useMemo(() => {
		if (isUpdate) {
			// let values = _.cloneDeep(currentForm.getFieldsValue());
			// const initialValues = _.cloneDeep(formInitialValues);
			// if (initialValues) values = Object.assign(initialValues, values)
			const values = returnFormData(currentForm.getFieldsValue())
			getList(1, 50, returnForm(values));
		}
	}, [isUpdate])

	const onSearch = (values: any) => {
		// let formData = _.cloneDeep(values);
		// const initialValues = _.cloneDeep(formInitialValues);
		// if (initialValues) formData = Object.assign(initialValues, formData)
		const formData = returnFormData(values)
		getList(1, 50, returnForm(formData));
	}

	const renderFilterBtn = (
		<Space size="small" style={{ marginBottom: 12 }}>
			<SearchButton />
			<ResetButton
				onClick={() => {
					localStorage.setItem('formValue', '')
					currentForm.resetFields();
					getList(1, 50, formInitialValues);
				}}
			/>
		</Space>
	)
	
  return (
    <div className={styles.root}>
			{
				filterData.length > 0 && (
					<Form
						form={currentForm}
						onFinish={onSearch}
						labelCol={filterData.length > 4 ? {xs: { span: 6 }} : undefined}
						className={formClassName}
						initialValues={formInitialValues}
					>
						<FilterForm
							filterData={filterData}
							btnArr={btnArr}
							pageHeadBtn={pageHeadBtn}
							renderFilterBtn={renderFilterBtn}
						/>

					</Form>
				)
			}
			<div className={styles.tableWarp} style={tableStyle}>
				{
					(pageTitle || isShowAddBtn) && (
						<div className={styles.pageHead} style={titleStyle}>
							<div className={styles.pageTitle}>{pageTitle}</div>
							{/* { pageHeadBtn } */}
							{
								isShowAddBtn && pageHeadBtn && (
									<div className={styles.pageHeadWarp}>
										{pageHeadBtn}
									</div>
								)
							}
						</div>
					)
				}
				{/* {
					isShowAddBtn && pageHeadBtn && (
						<div className={styles.pageHeadWarp}>
							{pageHeadBtn}
						</div>
					)
				} */}
				{tabsNode}
				<Table
					bordered
					loading={tableLoading}
					columns={columns}
					dataSource={dataSource ?? tableData}
					className={styles.formTable}
					pagination={false}
					rowKey={record => `${record.id}`}
					{...tableProps}
				/>
				{
					showPagination && tableData.length > 0 && (
						<div className={styles.pageBox} style={paginationStyle}>
							<Pagination
								onChange={onShowSizeChange}
								{...pagination}
							/>
						</div>
					)
				}

			</div>
    </div>
  )
}

export default TableComponent;
