import React, { FC, useState, useRef } from 'react'
import {  Input, Form, Image, DatePicker } from 'antd'
import styles from './PaperStatistics.module.scss'
import { Table } from '@/components'
import moment from 'moment'
import { getPaperStatistics } from '@/api/paper/index'
const { RangePicker } = DatePicker;
interface PaperStatisticsListProps {}

const PaperStatisticsList: FC<PaperStatisticsListProps> = (props) => {
  const [isUpdate, setIsUpdate] = useState(false)
  const [form] = Form.useForm()
  const childRef: any = useRef()

  const columns = [
    {
      title: 'id',
      width: '60px',
      dataIndex: 'id',
    },
    {
      title: '用户id',
      dataIndex: 'userId',
    },
    {
      title: '试卷ID',
      dataIndex: 'testPaperId',
    },
    {
      title: '试卷二维码',
      dataIndex: 'testPaperId',
      render: (text: any, record: any) => (
        <Image
          width={60}
          src={`https://image-1308113567.cos.ap-beijing.myqcloud.com/image/code/%E8%AF%95%E5%8D%B7${record.testPaperId}.jpg`}
        />
      ),
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      render: (text: any, record: any) =>
        record?.createTime && (
          <span>
            {moment(record?.createTime).format('YYYY-MM-DD HH:mm:ss')}
          </span>
        ),
    },
    {
      title: '更新时间',
      dataIndex: 'updateTime',
      render: (text: any, record: any) =>
        record?.createTime && (
          <span>
            {moment(record?.updateTime).format('YYYY-MM-DD HH:mm:ss')}
          </span>
        ),
    }
  ]
  const filterData = [
    {
      name: 'testPaperId',
      label: '试卷id',
      span:'4',
      children: <Input placeholder="请输入试卷id" />,
    },
    {
      name: 'userId',
      span:'4',
      label: '用户id',
      children: <Input placeholder="请输入用户id" />,
    },
    {
      name: 'akRangePicker',
      span:'10',
      label: '时间范围',
      children: <RangePicker  style={{width:'100%'}} format="YYYY-MM-DD HH:mm:ss" showTime  />
    }
  ]
 

  return (
    <div className={styles.root}>
      <Table
        columns={columns}
        filterData={filterData}
        parentForm={form}
        getListFun={getPaperStatistics}
        isUpdate={isUpdate}
        setIsUpdate={setIsUpdate}
        cRef={childRef}
      />
    </div>
  )
}

export default PaperStatisticsList
