import React, { FC, useState } from 'react'
import { Space, Input, message, Image } from 'antd';
import { useHistory } from 'react-router';
import moment from 'moment';
import styles from './UserPage.module.scss';
import { Popconfirm, Buttons, Table } from '@/components';
import { getUserList } from '@/api/user/user';
import _ from 'lodash';

const { DeleteButton, DefaultButton } = Buttons

interface UserPageProps {
 
}

const UserPage: FC<UserPageProps> = (props) => {
  const history = useHistory();
	const [isUpdate, setIsUpdate] = useState(false);

  const columns = [
		{
			title: '昵称',
			dataIndex: 'nickName',
		},
		{
			title: '头像',
			render: (text: any, record: any) => <Image width={100} src={record.avatarUrl} /> 
		},
		{
			title: '手机号',
			dataIndex: 'phone',
		},
		{
			title: 'openId',
			dataIndex: 'openId',
		},
		{
			title: '创建日期',
			render: (text: any, record: any) => record?.createTime && <span>{moment(record?.createTime).format('YYYY-MM-DD HH:mm:ss')}</span>
		},
		{
			title: '操作',
			key: 'status',
			width: 220,
			render: (text: any, record: any) => (
				<div>
					<Space size="small">
						<DefaultButton btnText="用户详情" onClick={() => history.push(`/user/userList/detail?userId=${record.id}`)} style={{ width: 88 }} />
					</Space>
				</div>
			)
		},
	];

	const filterData = [
		{name: 'phone', label: '手机号', children: <Input placeholder='请输入手机号' />},
		{name: 'nickName', label: '昵称', children: <Input placeholder='请输入昵称' />},
	]
	
  return (
    <div className={styles.root}>
      <Table
				columns={columns}
				// filterData={filterData}
				getListFun={getUserList}
				isUpdate={isUpdate}
				setIsUpdate={setIsUpdate}
			/>
    </div>
  )
}

export default UserPage;
