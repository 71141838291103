import React, {FC} from 'react'
import {HashRouter, Route, Switch} from 'react-router-dom';
import Charts from './Charts/Charts'
import CurriculumList from './Curriculum/CurriculumList'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import EditCurriculum from './Curriculum/EditCurriculum/EditCurriculum'
import CurriculumDetail from './Curriculum/CurriculumDetail/CurriculumDetail'
import UserListPage from './UserManagement/UserPage';
import UserDetail from './UserManagement/UserDetail/UserDetail';
// 图文课程
import SketchList from './Sketch/SketchList'
import SketchDetail from './Sketch/SketchDetail/SketchDetail'
import OperateSketch from './Sketch/OperateSketch/OperateSketch'

// 试卷管理
import PaperList from './Paper/PaperList'
import OperatePaper from './Paper/OperatePaper/OperatePaper' //试卷操作

// 试题管理
import QuestionsList from './Questions/QuestionsList'
import OperateQuestions from './Questions/OperateQuestions/OperateQuestions' //试卷操作
import QuestionsDetail from './Questions/QuestionsDetail/QuestionsDetail'
// 试题统计
import PaperStatistics from './PaperStatistics/PaperStatistics'

const RouteComponent: FC = (props) => (
	<HashRouter>
		<Switch>
			<Route path='/charts/list' component={Charts}/>
			<Route exact path='/curriculum/list' component={CurriculumList}/>
			<Route exact path='/curriculum/list/detail' component={CurriculumDetail}/>
			<Route exact path='/curriculum/list/detail' component={CurriculumDetail}/>
			<Route exact path='/user/userList' component={UserListPage}/>
			<Route exact path='/user/userList/detail' component={UserDetail}/>
      {/* 图文课程管理 */}
			<Route exact path='/sketch/list' component={SketchList}/>
			<Route exact path='/sketch/list/detail' component={SketchDetail}/>
			<Route exact path='/sketch/list/add' component={OperateSketch}/>
			<Route exact path='/sketch/list/edit' component={OperateSketch}/>
      {/* 试卷管理 */}
			<Route exact path='/paper/list' component={PaperList}/>
			<Route exact path='/paper/list/add' component={OperatePaper}/>
			<Route exact path='/paper/list/edit' component={OperatePaper}/>
      {/* 试卷内试题管理 */}
      <Route exact path='/questions/list' component={QuestionsList}/>
			<Route exact path='/questions/list/add' component={OperateQuestions}/>
			<Route exact path='/questions/list/edit' component={OperateQuestions}/>
			<Route exact path='/questions/list/detail' component={QuestionsDetail}/>
      {/* 试卷统计 */}
      <Route exact path='/paper/statistics' component={PaperStatistics}/>
		</Switch>
	</HashRouter>
)

export default RouteComponent;