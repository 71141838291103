import React, { FC, useState, useRef } from 'react'
import { Space, Input, Select, message, Form } from 'antd'
import { useHistory } from 'react-router'
import moment from 'moment';
import styles from './SketchList.module.scss'
import { Popconfirm, Buttons, Table } from '@/components'
import { getSketchList,deleteSketch } from '@/api/sketch/index'
import { DefaultButton } from '@/components/Buttons/Buttons'

const { DeleteButton, EditButton, AddButton } = Buttons

// const { Option } = Select
// const statusOptions = [
//   { label: '上架', value: 1 },
//   { label: '下架', value: 0 },
// ]
interface SketchListProps {}

const SketchList: FC<SketchListProps> = (props) => {
  const history = useHistory()
  const [isUpdate, setIsUpdate] = useState(false)
  const [form] = Form.useForm()
  const childRef: any = useRef()


  const setStorage = () => {
    childRef?.current?.setFilterConditions()
  }

  const columns = [
    {
      title: 'id',
      width:'60px',
      dataIndex: 'id',
    },
    {
      title: '图文课程标题',
      dataIndex: 'name',
    },
    {
      title: '描述',
      dataIndex: 'description',
    },
    // {
    //   title: '状态',
    //   dataIndex: 'status',
    //   render: (status: number | undefined) => {
    //     return (status === 1 ? <span>上架</span> : <span>下架</span>)
    //   },
    // },
    {
      title: '更新时间',
      dataIndex: 'updateTime',
			render: (text: any, record: any) => record?.createTime && <span>{moment(record?.updateTime).format('YYYY-MM-DD HH:mm:ss')}</span>
    },
    {
      title: '操作',
      key: 'status',
      render: (text: any, record: any) => (
        <div>
          <Space size="small">
            <EditButton
              onClick={() => {
                setStorage()
                history.push(`/sketch/list/edit?id=${record.id}`)
              }}
            />
            <Popconfirm
							title="确认删除吗？"
							onConfirm={async () => {
								onDelete(record.id)
							}}
						>
							<DeleteButton />
						</Popconfirm>
            <DefaultButton
              btnText="图文课程详情"
              onClick={() =>
                history.push(
                  `/sketch/list/detail?id=${record.id}`
                )
              }
              style={{ width: 88 }}
            />
          </Space>
        </div>
      ),
    },
  ]

  const filterData = [
    {
      name: 'name',
      label: '图文课程名称',
      children: <Input placeholder="请输入图文课程名称" />,
    }
    // {
    //   name: 'status',
    //   label: '状态',
    //   children: (
    //     <Select placeholder="请选择状态">
    //       {statusOptions.map((i, index) => (
    //         <Option key={index} value={i.value}>
    //           {i.label}
    //         </Option>
    //       ))}
    //     </Select>
    //   ),
    // },
  ]
  const formInitialValues = { adapt: 'all' }
	const onDelete = async (id: number) => {
		try {
			const res = await deleteSketch(id);
      
			if (res ===0) {
				message.success('删除成功');
				setIsUpdate(true);
			// } else {
			// 	message.error('删除失败');
			}
		} catch (error) {
			
		}
	}


  return (
    <div className={styles.root}>
      <Table
        columns={columns}
        filterData={filterData}
        parentForm={form}
        formInitialValues={formInitialValues}
        getListFun={getSketchList}
        isUpdate={isUpdate}
        setIsUpdate={setIsUpdate}
        cRef={childRef}
        pageHeadBtn={
          <AddButton
            className={styles.addBtn}
            style={{ marginBottom: 0 }}
            name="新增图文课程"
            onClick={() => history.push('/sketch/list/add')}
          />
        }
      />
    </div>
  )
}

export default SketchList
