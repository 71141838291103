import React, { useState, FC } from 'react'
import { Form, Input, Button, Radio, message, Modal } from 'antd'
import {SAVE_USER_INFO} from '@/redux/action'
import moment from 'moment'
import { useHistory } from 'react-router';
import './Login.scss'
import {useDispatch} from "redux-react-hook";
import Logo from '@/assets/image/logo.png';
import usernameIcon from '@/assets/image/username_icon.png';
import passwordIcon from '@/assets/image/password_icon.png';
import _ from 'lodash';
// import ArrowButton from '@/components/ArrowButton/ArrowButton';
import { postLogin } from '@/api/user/user';

interface ILoginProps{
  
}

const Login: FC<ILoginProps> = (props) => {

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false)
  const history = useHistory();

  const onFinish = async (values: any) => {
    try {
      setLoading(true)
      const formData = {
        userName: values.userName,
        password: values.password,
      }
      const data = await postLogin(formData)
      const expiresDate = moment().add(7, 'd').toDate()
      // @ts-ignore
      const expires = expiresDate.toGMTString ? `expires=${moment().add(7, 'd').toDate().toGMTString()}` : ''
      document.cookie = `token=${data.token};${expires}`
      // document.cookie = `token=${data.token}`
      dispatch({ type: SAVE_USER_INFO, userInfo: {userId: data.userId, ...data.user} })
      setLoading(false)
      message.success('登录成功');
      history.push('/user/userList');
    } catch (error) {
      // message.error('登录失败');
      setLoading(false)
    }
  }

  return (
    <div className="login-page">
      <div className="login-page-r">
        <img src={Logo} alt="" className="login-bg1" />
        <div className={'login-container'}>
          <div className="form-head">
            <div className="sub-title">欢迎登录</div>
            <div className="form-title">作强消防运营平台</div>
          </div>
          <Form form={form} onFinish={onFinish} className="login-form">
            <div className='send-code-wrap'>
              <Form.Item
                required={false}
                name="userName"
                // label="用户名"
              >
                <Input
                  placeholder="请输入用户名"
                  size='large'
                  prefix={<img src={usernameIcon} alt="" className="label-icon" />}
                />
              </Form.Item>
              <Form.Item
                required={false}
                name="password"
                // label="密码"
              >
                <Input
                  placeholder="请输入密码"
                  size='large'
                  type="password"
                  prefix={<img src={passwordIcon} alt="" className="label-icon" />}
                />
              </Form.Item>
            </div>
            {/* <ArrowButton
              // btnText={tabKey === '2' ? '立即注册' : '立即登录'}
              btnText='立即登录'
              isAntBtn
              loading={loading}
              // disabled={tabKey === '2' && !isCheck}
              className='searchBtn'
              isSubmit
              arrowStyle={{ top: 1 }}
            /> */}
            {/* <div className="login-btn">立即登录</div> */}
            <div className="forget-password">
              <span
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  Modal.info({
                    title: '请您联系管理员进行密码修改',
                    onOk() {},
                    className: 'forgetPassword'
                  });
                }}
              >
                忘记密码？
              </span>
            </div>
            <Button type="primary" className="login-btn" htmlType="submit" loading={loading}>
              登录
            </Button>
          </Form>
        </div>
      </div>
    </div>
  )

}

export default Login
