import React, {FC} from 'react'
import styles from './Buttons.module.scss'
import deleteIcon from '@/assets/components/delete_icon.svg';
import editIcon from '@/assets/components/edit_icon.svg';
import uploadIcon from '@/assets/components/upload_icon.svg';
import viewIcon from '@/assets/components/view_icon.svg';
import copyIcon from '@/assets/components/copy-icon.png';
// import downloadIcon from '@/assets/components/download_icon.svg';
import { Button, message } from 'antd'
import { PlusOutlined, SearchOutlined, HistoryOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
// import { showFile } from '@/utils/mainUtils';
import consultIcon from '@/assets/components/consult_icon.svg';

interface IButtonProps{
  onClick?: ((event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void);
  style?: React.CSSProperties;
  title?: string;
  className?: string;
  isShowModal?: boolean;
  btnText?: string;
  noSubmit?: boolean;
}

export const DefaultButton: FC<IButtonProps> = (props) => {
  const { style, onClick, btnText } = props

  return (
    <span className={styles.DefaultBtn} onClick={onClick} style={style}>
      {btnText}
    </span>
  )
}

export const DeleteButton: FC<IButtonProps> = (props) => {
  const { style, onClick } = props

  return (
    <span className={styles.deleteBtn} onClick={onClick} style={style}>
      <img alt=""src={deleteIcon} className={styles.btnIcon} />
      删除
    </span>
  )
}

export const EditButton: FC<IButtonProps> = (props) => {
  const { style, onClick, btnText } = props

  return (
    <span className={styles.editBtn} onClick={onClick} style={style}>
      <img alt=""src={editIcon} className={styles.btnIcon} />
      {btnText ?? '编辑'}
    </span>
  )
}

export const CopyButton: FC<IButtonProps> = (props) => {
  const { style, onClick, btnText } = props

  return (
    <span className={styles.copyBtn} onClick={onClick} style={style}>
      <img alt=""src={copyIcon} className={styles.btnIcon} style={{ marginTop: 0 }} />
      { btnText || '复制' }
    </span>
  )
}

export const UploadButton: FC<IButtonProps> = (props) => {
  const { style, onClick } = props

  return (
    <span className={styles.uploadBtn} onClick={onClick} style={style}>
      <img alt=""src={uploadIcon} className={styles.btnIcon} />
      上传
    </span>
  )
}

export const ViewButton: FC<IButtonProps> = (props) => {
  const { style, onClick } = props

  return (
    <span className={styles.viewBtn} onClick={onClick} style={style}>
      <img alt=""src={viewIcon} className={styles.btnIcon} />
      查看
    </span>
  )
}

export const SearchButton: FC<IButtonProps> = (props) => {
  const { style, onClick, noSubmit } = props

  return (
    <Button className={styles.searchBtn} htmlType={!noSubmit ? "submit" : undefined} onClick={onClick} style={style} icon={<SearchOutlined />} >
      查询
    </Button>
  )
}

export const ExportButton: FC<IButtonProps> = (props) => {
  const { style, onClick, btnText = '导出' } = props

  return (
    <span className={styles.exportButton} onClick={onClick} style={style}>
      {btnText}
    </span>
  )
}

export const ResetButton: FC<IButtonProps> = (props) => {
  const { style, onClick } = props

  return (
    <div className={styles.resetBtn} onClick={onClick} style={style}>
      <HistoryOutlined />
      重置
    </div>
  )
}

export const HideButton: FC<IButtonProps> = (props) => {
  const { style, onClick } = props

  return (
    <div className={styles.hideBtn} onClick={onClick} style={style}>
      <EyeInvisibleOutlined />
      隐藏
    </div>
  )
}

export const CzButton: FC<IButtonProps> = (props) => {
  const { style, onClick } = props

  return (
    <div className={styles.czBtn} onClick={onClick} style={style}>
      <HistoryOutlined />
      重置
    </div>
  )
}

export const ConsultButton: FC<IButtonProps> = (props) => {
  const { style, onClick, className } = props
  const clickBtn = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    if (onClick) {
      onClick(e);
    } else {
      try {
        // @ts-ignore
        window._MEIQIA('showPanel')
      } catch (error) {
        message.error('打开聊天失败，请手动打开')
      }
    }
  }
  return (
    <div className={`${styles.consultant} ${styles.contact} ${className}`} style={style} onClick={clickBtn}>
      <img alt=""src={consultIcon} className={styles.consultIcon} />
      在线咨询
    </div>
  )
}

// export const DownloadButton: FC<IButtonProps & {templateUrl?: string}> = (props) => {
//   const { style, onClick, title, className, templateUrl, isShowModal } = props
// 	const [purchaseVisible, setPurchaseVisible] = useState(false);
  
//   const handleClick = (e: any) => {
//     if (isShowModal) {
//       setPurchaseVisible(true);
//       return;
//     }
//     if (onClick) {
//       onClick(e)
//     } else {
//       templateUrl && showFile(templateUrl)
//     }
//   }
//   return (
//     <>
//       <span className={`${styles.downloadBtn} ${className}`} onClick={handleClick} style={style}>
//         <img alt=""src={downloadIcon} className={styles.downloadIcon} />
//         {title || '参考样例下载'}
//       </span>
//     </>
//   )
// }

interface IAddButtonProps {
  title?: string;
  name: string;
  subtitle?: React.ReactNode;
}

export const AddButton: FC<IButtonProps & IAddButtonProps> = (props) => {
  const { title, name, onClick, style, subtitle, className } = props

  return (
    <div className={styles.titleWrap} style={style}>
      <div style={{ maxWidth: '80%' }}>
        {title && <div className={styles.title}>{title}</div>}
        {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
      </div>
      <Button
        icon={<PlusOutlined className={styles.addBtnIcon}/>}
        className={`${styles.addBtn} ${className}`}
        onClick={onClick}
      >
        {name}
      </Button>
    </div>
  )
}